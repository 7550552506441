import {
    LanguageDetection,
    LanguageDetectorBodyAttributeDetector,
    // CustomDetector,
    LanguageDetectorDocumentElementDetector,
    LanguageDetectorForcedLanguageDetector,
    LanguageDetectorMetaTagDetector,
    LanguageDetectorNavigatorDetector,
    LanguageDetectorQueryStringDetector,
    LanguageDetectorUrlDetector,
    LanguageDetectorWindowDetector,
} from "@hotelchamp/common";

export const languageDetector = new LanguageDetection([
    new LanguageDetectorForcedLanguageDetector(),
    new LanguageDetectorDocumentElementDetector(),
    new LanguageDetectorBodyAttributeDetector(),
    new LanguageDetectorMetaTagDetector(),
    new LanguageDetectorWindowDetector(),
    new LanguageDetectorQueryStringDetector(),
    new LanguageDetectorUrlDetector(),
    new LanguageDetectorNavigatorDetector(),
]);
