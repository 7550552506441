import { appTypes, log } from "@app/app";
import { commonTypes } from "@app/common";
import { propertyTypes } from "@app/property";
import { Analytics, AnalyticsContracts } from "@hotelchamp/common";

import { CustomDimensionKeys } from "../constants/CustomDimensionKeys";
import { TrackingActions } from "../constants/TrackingActions";
import {
    AddPaymentInfoEvent,
    AddShippingInfoEvent,
    AddToCartEvent,
    BeginCheckoutEvent,
    PurchaseEvent,
    RemoveFromCartEvent,
    SelectItemEvent,
    TItemType,
    TTrackingActionMap,
    ViewItemEvent,
    ViewItemListEvent,
} from "../types";
import { adaptBookableItems, adaptItemsByType, getMappedTrackingAction } from "../utils/trackingUtils";

interface IGenericObject {
    [key: string]: any;
}

export interface ITrackerConfig {
    analytics: Analytics;
    property?: propertyTypes.IProperty;
    trackerActionMap?: TTrackingActionMap;
    trackerCustomDimensionMap?: ITrackerCustomDimensionMap;
}

interface ITrackings {
    [key: string]: number;
}

export interface ITrackerCustomDimensionMap {
    [key: string]: string;
}

/**
 * TrackLimit
 * Defines how often a tracking should be tracked
 */
enum TrackLimit {
    None,
    OncePerPageview,
}

interface ITrackOptions {
    limit: TrackLimit;
}

/**
 * Tracker
 * Knows how to track events and state of the widget system
 * by making use of a configured instance of the
 * Analytics library
 */
export class Tracker {
    private analytics: Analytics;

    private trackings: ITrackings;

    private property?: propertyTypes.IProperty;

    private trackerActionMap: TTrackingActionMap;

    private trackerCustomDimensionMap: ITrackerCustomDimensionMap;

    public constructor(config: ITrackerConfig) {
        this.analytics = config.analytics;
        this.trackings = {};
        this.property = config.property || undefined;
        this.trackerActionMap = config.trackerActionMap || {};
        this.trackerCustomDimensionMap = config.trackerCustomDimensionMap || {};
    }

    public setProperty(property: propertyTypes.IProperty) {
        this.property = property;

        return this;
    }

    public getPropertyOrFail() {
        if (!this.property) {
            throw new Error("Property not set yet");
        }

        return this.property;
    }

    public searchPage = () => this.track(TrackingActions.SearchPage, {}, true);

    public searchQuery = ({ query }: { query: { [key: string]: any } }) => this.track(TrackingActions.SearchQuery, { query }, true);

    public viewItem = ({
        bookableItems,
        value,
        currency,
    }: {
        bookableItems: commonTypes.TBookableItem[];
        value: number;
        currency: string;
    }) =>
        this.track(
            TrackingActions.ViewItem,
            { ecommerce: { items: adaptBookableItems(bookableItems, this.getPropertyOrFail()), value, currency } as ViewItemEvent },
            true
        );

    public selectItem = ({
        itemListId,
        itemListName,
        bookableItems,
    }: {
        itemListId: string;
        itemListName: string;
        bookableItems: commonTypes.TBookableItem[];
    }) =>
        this.track(
            TrackingActions.SelectItem,
            {
                ecommerce: {
                    items: adaptBookableItems(bookableItems, this.getPropertyOrFail()),
                    item_list_id: itemListId,
                    item_list_name: itemListName,
                } as SelectItemEvent,
            },
            true
        );

    public viewItemList = ({
        itemListId,
        itemListName,
        bookableItems,
    }: {
        itemListId: string;
        itemListName: string;
        bookableItems: commonTypes.TBookableItem[];
    }) =>
        this.track(
            TrackingActions.ViewItemList,
            {
                ecommerce: {
                    items: adaptBookableItems(bookableItems, this.getPropertyOrFail()),
                    item_list_id: itemListId,
                    item_list_name: itemListName,
                } as ViewItemListEvent,
            },
            true
        );

    public addToCart = ({
        unadaptedItems,
        value,
        currency,
        itemType,
    }: {
        unadaptedItems: commonTypes.TBookableItem[] | commonTypes.TPriceableItem[];
        value: number;
        currency: string;
        itemType: TItemType;
    }) =>
        this.track(
            TrackingActions.AddToCart,
            {
                ecommerce: {
                    items: adaptItemsByType(unadaptedItems, itemType, this.getPropertyOrFail()),
                    value,
                    currency,
                } as AddToCartEvent,
            },
            true
        );

    public removeFromCart = ({
        unadaptedItems,
        value,
        currency,
        itemType,
    }: {
        unadaptedItems: commonTypes.TBookableItem[] | commonTypes.TPriceableItem[];
        value: number;
        currency: string;
        itemType: TItemType;
    }) =>
        this.track(
            TrackingActions.RemoveFromCart,
            {
                ecommerce: {
                    items: adaptItemsByType(unadaptedItems, itemType, this.getPropertyOrFail()),
                    value,
                    currency,
                } as RemoveFromCartEvent,
            },
            true
        );

    public beginCheckout = ({
        unadaptedItems,
        value,
        currency,
        coupon,
    }: {
        unadaptedItems: appTypes.IBookingState;
        value: number;
        currency: string;
        coupon?: string;
    }) =>
        this.track(
            TrackingActions.BeginCheckout,
            {
                ecommerce: {
                    items: adaptItemsByType(unadaptedItems, "booking", this.getPropertyOrFail()),
                    value,
                    currency,
                    coupon,
                } as BeginCheckoutEvent,
            },
            true
        );

    public addPaymentInfo = ({
        bookableItems,
        value,
        currency,
        paymentType,
        coupon,
    }: {
        bookableItems: commonTypes.TBookableItem[];
        value: number;
        currency: string;
        paymentType: string;
        coupon?: string;
    }) =>
        this.track(
            TrackingActions.AddPaymentInfo,
            {
                ecommerce: {
                    items: adaptBookableItems(bookableItems, this.getPropertyOrFail()),
                    value,
                    currency,
                    payment_type: paymentType,
                    coupon,
                } as AddPaymentInfoEvent,
            },
            true
        );

    public addShippingInfo = ({
        bookableItems,
        value,
        currency,
        shippingTier,
        coupon,
    }: {
        bookableItems: commonTypes.TBookableItem[];
        value: number;
        currency: string;
        shippingTier: string;
        coupon?: string;
    }) =>
        this.track(
            TrackingActions.AddShippingInfo,
            {
                ecommerce: {
                    items: adaptBookableItems(bookableItems, this.getPropertyOrFail()),
                    value,
                    currency,
                    shipping_tier: shippingTier,
                    coupon,
                } as AddShippingInfoEvent,
            },
            true
        );

    public purchase = ({
        bookableItems,
        value,
        currency,
        transactionId,
        shipping,
        tax,
        coupon,
    }: {
        bookableItems: commonTypes.TBookableItem[];
        value: number;
        currency: string;
        transactionId: string;
        shipping: number;
        tax?: number;
        coupon?: string;
    }) =>
        this.track(
            TrackingActions.Purchase,
            {
                ecommerce: {
                    items: adaptBookableItems(bookableItems, this.getPropertyOrFail()),
                    value,
                    currency,
                    transaction_id: transactionId,
                    shipping,
                    tax,
                    coupon,
                } as PurchaseEvent,
            },
            true
        );

    /**
     * Private methods
     */

    /**
     * track
     * these events will be tracked through the Analytics lib
     *
     * @param action
     * @param category
     * @param properties
     * @param isUserInteraction
     * @param options
     */
    private async track(
        action: TrackingActions,
        properties: IGenericObject,
        isUserInteraction = true,
        options: ITrackOptions = { limit: TrackLimit.None }
    ): Promise<AnalyticsContracts.TResponse | void> {
        const mappedAction = getMappedTrackingAction(action, this.trackerActionMap) as string;
        const key = JSON.stringify({ action: mappedAction, ...properties });
        const hasBeenTracked = this.trackings.hasOwnProperty(key);
        const hasLimit = options.limit !== TrackLimit.None;
        const hasLimitReached = hasLimit && hasBeenTracked;
        const category = "";

        if (!hasBeenTracked) {
            this.trackings[key] = 0;
        }

        this.trackings[key]++;

        if (!hasLimit || !hasLimitReached) {
            log.warn(
                `Tracker::track - WILL TRACK: action: ${mappedAction}, isUserInteraction: ${
                    isUserInteraction ? "true" : "false"
                }  properties: `,
                properties
            );

            const result = await this.analytics.track(mappedAction, category, properties, isUserInteraction);

            const adapterName = await this.analytics.getAdapter().getName();

            log.debug(
                `Tracker::track - action: ${mappedAction} (unmapped: ${action}}), isUserInteraction: ${
                    isUserInteraction ? "true" : "false"
                } | adapter: ${adapterName} | properties: `,
                properties,
                " | raw adapter data: ",
                result
            );

            return result;
        }
    }

    private set(key: string, value: string): Promise<AnalyticsContracts.TResponse> {
        const mappedKey = this.resolveMappedCustomDimensionKey(key) as string;
        const isKeyMapped = mappedKey !== key;

        log.debug(`Tracker::set - key: ${mappedKey}${isKeyMapped ? `(mapped from ${key})` : ""} and value: ${value}`);

        return this.analytics.set(mappedKey, value);
    }

    private resolveMappedCustomDimensionKey = (key: CustomDimensionKeys | string) =>
        key in this.trackerCustomDimensionMap ? this.trackerCustomDimensionMap[key] : key;
}
