import * as React from "react";

import { cn } from "../../lib/utils";

export interface IInputProps extends React.HTMLAttributes<HTMLInputElement> {
    prefixIcon?: string | React.ReactElement;
    suffixIcon?: React.ReactElement | string;
    prefixClassName?: string;
    type?: React.HTMLInputTypeAttribute;
    label?: string;
    name?: string;
    value?: string | undefined | null | number;
    defaultValue?: string | undefined | number;
    className?: string;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    disabled?: boolean;
    error?: boolean;
    autoComplete?: "off" | string;
}

export const Input = React.forwardRef(
    (
        {
            label,
            name,
            prefixIcon,
            readOnly,
            suffixIcon,
            type,
            placeholder,
            className,
            prefixClassName,
            disabled,
            autoComplete = "",
            error = false,
            ...restProps
        }: IInputProps,
        ref: React.ForwardedRef<HTMLInputElement>
    ) => (
        <div className={`rounded-lg ${prefixIcon || suffixIcon ? "relative" : ""}`}>
            {prefixIcon && (
                <div className={cn("absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none", prefixClassName)}>
                    {prefixIcon}
                </div>
            )}

            <input
                {...restProps}
                value={restProps.value === null ? "" : restProps?.value}
                ref={ref}
                id={name}
                name={name}
                disabled={disabled}
                autoComplete={autoComplete}
                readOnly={readOnly}
                type={type}
                placeholder={placeholder ?? label}
                className={cn(
                    "appearance-none block w-full px-4 py-3 border border-[#c7c7c7] text-gray-800 rounded-lg text-base placeholder-[#616F82] placeholder:text-base focus:outline-none focus:ring-primary focus:border-primary",
                    { "pl-10": prefixIcon },
                    { "border-red-300 text-red-300 placeholder:text-red-300": error },
                    className
                )}
            />

            {!!suffixIcon && <div className="absolute inset-y-0 right-0 pr-3 text-gray-500 sm:text-sm flex items-center">{suffixIcon}</div>}
        </div>
    )
);
