import { bookingTypes } from "@app/booking";
import { PaymentIntentCaptureMethod, PaymentTypes, paymentTypes } from "@app/payment";
import React from "react";

import { CheckoutSessionDefaults } from "../Constants/CheckoutSessionDefaults";
import { CheckoutSessionStatus } from "../Constants/CheckoutSessionStatus";
import { ICheckoutSessionState } from "../types";

export interface ICheckoutSessionProviderStartSessionProps {
    payment_type?: PaymentTypes;
    forceNew?: boolean;
    forceReservedBooking?: Partial<bookingTypes.IReservedBooking>;
}

export interface ICheckoutSessionContext {
    state: ICheckoutSessionState;
    setState: (state: Partial<ICheckoutSessionState>) => Promise<void>;
    startSession: (props: ICheckoutSessionProviderStartSessionProps) => Promise<void>;
    booking?: bookingTypes.IReservedBooking;
    paymentIntent?: paymentTypes.IPaymentIntent;
    paymentSetupIntent?: paymentTypes.IPaymentSetupIntent;
    upsertCheckoutSession: () => Promise<ICheckoutSessionState>;
    clearCheckoutSession: () => Promise<void>;
    hasActiveCheckoutSession: boolean;
    isCheckoutSessionInitializing: boolean;
    isLoading: boolean;
    updateSessionExpiredAt: () => void;
}

export const INITIAL_CHECKOUT_SESSION_CONTEXT_VALUE: ICheckoutSessionContext = {
    state: {
        capture_method: PaymentIntentCaptureMethod.AutomaticAsync,
        status: CheckoutSessionStatus.InProgress,
        payment_type: PaymentTypes.Direct,
        expired_at: Date.now() + CheckoutSessionDefaults.SessionTtl,
        has_last_payment_error_been_shown: false,
    },
    setState: () => {
        throw new Error("Not initialized yet");
    },
    startSession: () => {
        throw new Error("Not initialized yet");
    },
    isLoading: false,
    hasActiveCheckoutSession: false,
    isCheckoutSessionInitializing: true,
    upsertCheckoutSession: () =>
        Promise.resolve({
            session_id: "",
            status: CheckoutSessionStatus.InProgress,
            capture_method: PaymentIntentCaptureMethod.AutomaticAsync,
            payment_type: PaymentTypes.Direct,
            has_last_payment_error_been_shown: false,
            expired_at: Date.now() + CheckoutSessionDefaults.SessionTtl,
        }),
    clearCheckoutSession: () => Promise.resolve(),
    updateSessionExpiredAt: () => {
        throw new Error("Not initialized yet");
    },
};

export const CheckoutSessionContext = React.createContext<ICheckoutSessionContext>(INITIAL_CHECKOUT_SESSION_CONTEXT_VALUE);

CheckoutSessionContext.displayName = "CheckoutSessionContext";
