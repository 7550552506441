export const config: { [key: string]: any } = {
    appEnv: process.env.NODE_ENV || "",
    globalVendorNamespace: process.env.VITE_GLOBAL_VENDOR_NAMESPACE || "__HC__",
    globalAppNamespace: process.env.VITE_GLOBAL_APP_NAMESPACE || "ibe",
    apiUrl: process.env.VITE_OBE_API_URL || "",
    appUrl: `//${process.env.VITE_DOMAIN}` || "",
    wsServicesApiUrl: process.env.VITE_WS_SERVICES_API_URL || "",
    googleFontsApiKey: process.env.VITE_GOOGLE_FONT_API || "",
    sentryDsn: process.env.VITE_SENTRY_DSN || "",
    amplitudeApiKey: process.env.VITE_AMPLITUDE_API_KEY || "",
    amplitudeDebugLevel: process.env.VITE_AMPLITUDE_DEBUG_LEVEL || "",
    pusherAppKey: process.env.VITE_PUSHER_APP_KEY || "",
    pusherAppCluster: process.env.VITE_PUSHER_APP_CLUSTER || "",
    demoMode: Number(process.env.VITE_DEMO_MODE) || 0,
    stripePublishableKey: process.env.VITE_STRIPE_PUBLISHABLE_KEY || "",
};
