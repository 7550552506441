import { availabilityTypes } from "@app/availability";
import { differenceInDays, eachDayOfInterval, format } from "date-fns";

export const availableDateRange = (calendar: availabilityTypes.ICalendarAvailability, fromDate: string, toDate: string) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const validStartDate = differenceInDays(startDate, new Date()) >= 0;
    const validEndDate = differenceInDays(endDate, startDate) >= 0;

    if (!validStartDate || !validEndDate) {
        return false;
    }

    if (Object.keys(calendar).length) {
        const betweenDates = eachDayOfInterval({ start: startDate, end: endDate });

        if (!calendar?.[format(startDate, "yyyy-MM-dd")] || !calendar?.[format(endDate, "yyyy-MM-dd")]) {
            return false;
        }

        for (const d of betweenDates) {
            if (!calendar?.[format(d, "yyyy-MM-dd")]) {
                return false;
            }
        }
    }

    return true;
};
