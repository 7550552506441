import { commonTypes } from "@app/common";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue, TSelectProps } from "@ui";
import React from "react";

export interface ICurrencySelectProps extends TSelectProps {
    currencies: commonTypes.ICurrency[];
    onChange?: (value: string) => void;
    placeholder?: string;
}

export const CurrencySelect = React.forwardRef(
    ({ currencies, placeholder, onChange, ...selectProps }: ICurrencySelectProps, ref: React.ForwardedRef<unknown>) => (
        <Select {...selectProps} onValueChange={onChange}>
            <SelectTrigger className="">
                <SelectValue placeholder={placeholder || "Select currency"} />
            </SelectTrigger>
            <SelectContent className="overflow-y-scroll">
                <SelectGroup className="overflow-y-scroll max-h-52" data-scrollable>
                    {currencies.map((currency) => (
                        <SelectItem data-testid={`currency-item-${currency.code}`} key={currency.code} value={currency.code}>
                            {currency.label}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
);
