import { format as formatDate, isDate, Locale } from "date-fns";
import { enUS, nl, th } from "date-fns/locale";
import i18n from "i18next";

const locales: { [key: string]: Locale } = { enUS, nl, th }; // used to look up the required locale

const mapToDateFnsLocale: { [key: string]: string } = {
    "en-US": "enUS",
    "nl-NL": "nl",
    "th-TH": "th",
};

i18n.init({
    partialBundledLanguages: true,
    ns: [],
    resources: {},
    fallbackLng: "en-US",
    saveMissing: true,
    parseMissingKeyHandler: () => null,
    missingKeyNoValueFallbackToKey: true,
    interpolation: {
        format: (value: any, format?: string, lng?: string): any => {
            if (isDate(value) && format && lng) {
                const locale = locales[mapToDateFnsLocale[lng]];
                const formattedDate = formatDate(value, format, { locale });

                // Capitalize the first letter of the formatted month
                return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
            }
        },
    },
});

export default i18n;
