import { appConfig, appUtils, storageManager } from "@app/app";
import { bookingTypes } from "@app/booking";
import { CheckoutStorageKeys } from "@app/checkout";
import { colorUtils, commonHooks } from "@app/common";
import { propertyHooks } from "@app/property";
import { DocumentObserverEventTypes, UtilsIdentifier } from "@hotelchamp/common";
import { merge } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMachine } from "react-robot";

import { AppZIndexes } from "../constants/AppZIndexes";
import { StorageKeys } from "../constants/StorageKeys";
import { documentObserver, i18next } from "../services";
import { appStateMachine } from "../services/appStateMachine";
import { languageDetector } from "../services/languageDetector";
import { log } from "../services/log";
import { IAppState, IIbeConfig } from "../types";
import { resolvePropertyIdOrFail } from "../utils/appUtils";
import { detectValidLanguageForProperty, resolveValidLanguage } from "../utils/languageDetector";
import { AppStateContext, IAppStateContext, INITIAL_APP_STATE } from "./AppStateContext";

export interface IAuthLoaderProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

const win = window as any;
let IS_THEME_APPLIED = false;
const IBE_DEFAULT_COLOR = "#0085FF";

const fallBackThemeColor = win?.[appConfig.globalVendorNamespace]?.[appConfig.globalAppNamespace]?.primaryColor;

export function AppStateContextProvider({ children }: IAuthLoaderProviderProps) {
    const propertyId = resolvePropertyIdOrFail();
    const [stateMachineState, send] = useMachine(appStateMachine);

    const { data: translations } = commonHooks.useGetTranslations(propertyId, { enabled: !!propertyId });
    const [state, setState] = useState<IAppState>({} as IAppState);
    const [isSessionStateInitialized, setIsSessionStateInitialized] = useState<boolean>(false);
    const [restorableGuestDetails, setRestorableGuestDetails] = useState<null | bookingTypes.IGuestDetails>(null);
    const sessionStorage = storageManager.getGlobalSession();
    const {
        data: property,
        isLoading: isPropertyLoading,
        isFetched: isPropertyFetched,
    } = propertyHooks.useGetProperty(propertyId, { enabled: !!propertyId });
    const currentWidgetState = stateMachineState.name;
    const isWidgetStateHidden = currentWidgetState === "hidden" || currentWidgetState === "inactive";
    const activeLanguage = state?.language;
    const isInitialized = isSessionStateInitialized && isPropertyFetched;
    const getBookingState: any = null;
    const setBookingStateRef = useRef(getBookingState);
    const setBookingEngineStateResolver = (fn: any) => {
        setBookingStateRef.current = fn;
    };

    // Restore the guest details from storage
    useEffect(() => {
        sessionStorage.get(CheckoutStorageKeys.CheckoutGuestDetailsState).then((nextRestorableGuestDetails) => {
            setRestorableGuestDetails(nextRestorableGuestDetails || null);
        });
    }, []);

    // Add an IBE Config updater to use from outside the App.
    const ibeConfigUpdater = (config: IIbeConfig) => setState((oldState) => ({ ...oldState, initConfig: config }));
    const setConfigRef = useRef(ibeConfigUpdater);
    setConfigRef.current = ibeConfigUpdater;

    const detectAndSetLanguage = useCallback(() => {
        const languageDetectionResult = detectValidLanguageForProperty(languageDetector, property);
        const { resolvedLanguage } = languageDetectionResult;

        if (resolvedLanguage && (!activeLanguage || resolvedLanguage !== activeLanguage)) {
            setState((currentState) => ({
                ...currentState,
                activeLanguage: resolvedLanguage,
            }));

            log.debug("AppStateContextProvider - detectAndStoreLanguage result", languageDetectionResult);
        }
    }, [setState, activeLanguage, property]);

    const setLanguage = useCallback(
        (nextLanguage: string) => {
            if (!property) {
                log.warn("Property is not loaded yet");

                return;
            }

            const languageResolvingResult = resolveValidLanguage(
                nextLanguage,
                property?.languages,
                property?.settings.default_language.code
            );

            if (!languageResolvingResult.didUseFallback) {
                log.debug("AppStateContextProvider - setLanguage result", languageResolvingResult);

                setState((currentState) => ({ ...currentState, language: languageResolvingResult.resolvedLanguage }));
            }
        },
        [setState, activeLanguage, state.property]
    );

    useEffect(() => {
        log.debug("AppStateContextProvider - state updated", state);
    }, [state]);

    useEffect(() => {
        if (property) {
            setState((oldState) => ({ ...oldState, property }));
        }
    }, [property]);

    // Apply the property theme styles
    useEffect(() => {
        if (property && !IS_THEME_APPLIED) {
            const primaryColor = property.theme.colors.primary || fallBackThemeColor || IBE_DEFAULT_COLOR;
            const color = colorUtils.convertHexToHsl(primaryColor);

            if (color !== null) {
                const colorString = `${color.h}, ${color.s}, ${color.l}`;
                const colorArray = colorString.split(",");
                colorArray[2] = " 92%";

                if (window["hc-ibe-root"]) {
                    IS_THEME_APPLIED = true;
                    window["hc-ibe-root"].style.setProperty("--hc-primary", colorString);
                    window["hc-ibe-root"].style.setProperty("--hc-secondary", colorArray.join(","));
                    window["hc-ibe-root"].style.setProperty("--hc-secondary-foreground", colorString);
                }
            }
        }
    }, [property]);

    /**
     * App Facade:
     *
     * Exposes a search function to trigger searching availability from outside the app.
     * Additional methods can be added as needed.
     *
     * Example availability search call:
     *  window.__HC__.ibe.search({
            "checkin": new Date('2024-06-25'),
            "checkout": new Date('2024-06-29'),
            "adultCount": 2,
            "childCount": 1,
            "roomCount": 1,
            "promoCode": "",
            "languageCode": "en"
        });
     */
    useEffect(() => {
        win[appConfig.globalVendorNamespace] = {
            ...(win[appConfig.globalVendorNamespace] || {}),
            [appConfig.globalAppNamespace]: {
                ...(win[appConfig.globalVendorNamespace] || {})[appConfig.globalAppNamespace],
                search: (newConfig?: IIbeConfig) => {
                    setState((oldState) => ({
                        ...oldState,
                        searchParams: !newConfig ? oldState.searchParams : "",
                    }));

                    if (newConfig) {
                        setConfigRef.current(newConfig);
                    }

                    send("SHOW");
                },
                getBooking: () => {
                    const bookingState = setBookingStateRef.current ? setBookingStateRef.current() : null;

                    return appUtils.ibeStateToTrackingSystemBooking(bookingState, restorableGuestDetails, state.cart || null);
                },
                getWidgetState: () => currentWidgetState,

                detectLanguage: detectAndSetLanguage,

                setLanguage,

                requestWidgetTransition: send,

                getAppZIndexes: () => AppZIndexes,
            },
        };
    }, [
        setState,
        send,
        state.restoreableWidgetState,
        currentWidgetState,
        property,
        setLanguage,
        detectAndSetLanguage,
        restorableGuestDetails,
        state.cart,
    ]);

    // set state in storage on change
    useEffect(() => {
        const isEmptyState = !Object.keys(state).length;

        if (!isEmptyState) {
            // set state in storage but remove "property" because to prevent storage limit (since the size of the object)
            storageManager.getExtendedGlobalSession().set(StorageKeys.AppState, { ...state, property: undefined });
        }
    }, [state]);

    /**
     * When the ibe state value changes, sync it with storage so that
     * the state can be restored once the page reloads
     */
    useEffect(() => {
        if (!isWidgetStateHidden && isInitialized) {
            setState((prevAppState) => ({ ...prevAppState, restoreableWidgetState: currentWidgetState }));
        }
    }, [currentWidgetState]);

    // Restore state from storage initially when component is mount
    useEffect(() => {
        sessionStorage.get<IAppState>(StorageKeys.AppState, INITIAL_APP_STATE).then((storageAppState) => {
            const sessionId = storageAppState.sessionId || UtilsIdentifier.uuid();
            const searchParams =
                !storageAppState.initConfig && !storageAppState.searchParams ? "?p=base&c=search" : storageAppState.searchParams;

            setState((currentState) => merge(currentState, { ...storageAppState, sessionId, searchParams }));

            setIsSessionStateInitialized(true);
        });
    }, []);

    // Load and initialize translations
    useEffect(() => {
        const trs = translations || {};

        Object.keys(trs).forEach((lang) => {
            const langTrs = trs[lang] || ({} as any);

            Object.keys(langTrs).forEach((namespace) => {
                i18next.addResourceBundle(lang, namespace, langTrs[namespace] as any);
            });
        });
    }, [translations]);

    useEffect(() => {
        if (isInitialized) {
            const documentObserverDetacher = documentObserver.on(DocumentObserverEventTypes.UrlChange, detectAndSetLanguage);

            // @TODO - first make sure if this is what we want, redetect language when url changes and uddate IBE
            // detectAndSetLanguage();

            return documentObserverDetacher;
        }
    }, [detectAndSetLanguage, isInitialized]);

    const value: IAppStateContext = {
        state,
        setBookingEngineStateResolver,
        setState: useCallback(setState, [setState]),
        requestWidgetTransition: send,
        detectLanguage: detectAndSetLanguage,
        widgetState: stateMachineState,
        propertyId,
        isPropertyLoading,
        isInitialized,
    };

    return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
}
