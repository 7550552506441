import { appTypes } from "@app/app";
import { useActiveBookingContext, useBookingEngineStateContext } from "@app/app/hooks";
import { cn, Flex } from "@app/componentLibrary";
import { MoneyComponent } from "@hotelchamp/common";
import React from "react";

import { commonTypes } from "..";
import { useGetPromoDiscount } from "../hooks";
import { getRateBreakdown } from "../utils/rateUtils";

export interface IPriceableItemPriceProps extends React.HTMLAttributes<HTMLDivElement> {
    item: commonTypes.TBookableItem | number | undefined;
    discountedPrice?: number;
    booking?: appTypes.IBooking;
    moneyClassName?: string;
    outdatedMoneyClassName?: string;
    outdatedMoneyStrikeThroughClassName?: string;
    plain?: boolean;
    formatOptions?: any;
}

export const PriceableItemPrice = ({
    item,
    discountedPrice,
    booking,
    className,
    moneyClassName,
    outdatedMoneyClassName,
    outdatedMoneyStrikeThroughClassName,
    plain = false,
    formatOptions,
}: IPriceableItemPriceProps) => {
    const { getState, getLengthOfStay, exchangeRates } = useBookingEngineStateContext();
    const { activeBookingIndex } = useActiveBookingContext();
    const { propertyId, promoCode, bookingState } = getState();
    const { data: promoDiscount } = useGetPromoDiscount(promoCode || "", propertyId || "", { enabled: !!promoCode && !!propertyId });
    const bookings = bookingState?.bookings || [];
    const activeBooking = booking || (activeBookingIndex !== -1 ? bookings[activeBookingIndex] : null);

    if (!item) {
        return null;
    }

    const rateBreakdown = getRateBreakdown({
        los: getLengthOfStay(),
        bookingEngineState: getState(),
        booking: booking || activeBooking || undefined,
        promoDiscount,
        adultCount: activeBooking?.adultsCount,
        childCount: activeBooking?.childCount,
        infantCount: activeBooking?.infantCount,
        priceableItem: item,
        exchangeRates,
        forcedDiscountedPrice: discountedPrice,
    });

    if (plain) {
        return item && rateBreakdown.exchangedBaseRate ? (
            <MoneyComponent
                amount={
                    (rateBreakdown.exchangedDiscountedRate ? rateBreakdown.exchangedDiscountedRate : rateBreakdown.exchangedBaseRate) / 100
                }
                locale={rateBreakdown.locale}
                currency={rateBreakdown.targetCurrency}
                formatOptions={formatOptions}
            />
        ) : null;
    }

    return item && rateBreakdown.exchangedBaseRate ? (
        <Flex data-testid="priceable-item" justifyContent="end" className={cn("gap-x-2", className)}>
            {!!rateBreakdown.exchangedDiscountedRate && (
                <span data-testid="priceable-item-discounted leading-none" style={{ lineHeight: "0px" }}>
                    <del className={cn("relative text-gray-400 text-sm no-underline", outdatedMoneyClassName)}>
                        <MoneyComponent
                            amount={rateBreakdown.exchangedBaseRate / 100}
                            locale={rateBreakdown.locale}
                            currency={rateBreakdown.targetCurrency}
                            formatOptions={formatOptions}
                        />
                        <div
                            className={cn(
                                "absolute h-0 w-full border-t mx-0.5 border-t-gray-400 border-b border-b-gray-200 top-1/2",
                                outdatedMoneyStrikeThroughClassName
                            )}
                        />
                    </del>
                </span>
            )}
            <span data-testid="priceable-item-original" className={cn("text-base text-gray-900 leading-none", moneyClassName)}>
                <MoneyComponent
                    amount={
                        (rateBreakdown.exchangedDiscountedRate ? rateBreakdown.exchangedDiscountedRate : rateBreakdown.exchangedBaseRate) /
                        100
                    }
                    locale={rateBreakdown.locale}
                    currency={rateBreakdown.targetCurrency}
                    formatOptions={formatOptions}
                />
            </span>
        </Flex>
    ) : null;
};
