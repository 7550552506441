export enum TrackingActions {
    SearchPage = "search_page",
    SearchQuery = "search_query",
    ViewItem = "view_item",
    ViewItemList = "view_item_list",
    SelectItem = "select_item",
    AddToCart = "add_to_cart",
    RemoveFromCart = "remove_from_cart",
    BeginCheckout = "begin_checkout",
    Purchase = "purchase",
    AddPaymentInfo = "add_payment_info",
    AddShippingInfo = "add_shipping_info",
}
