import { cn } from "@ui";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const alertVariants = cva(
    "relative w-full rounded-xl border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:",
    {
        variants: {
            variant: {
                default: "bg-gray-50 text-gray-500",
                destructive: "border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
);

type TAlertProps = React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>;

const Alert: React.FC<TAlertProps> = React.forwardRef<HTMLDivElement, TAlertProps>(({ className, variant, ...props }, ref) => (
    <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
));
Alert.displayName = "Alert";

type TAlertTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

const AlertTitle: React.FC<TAlertTitleProps> = React.forwardRef<HTMLParagraphElement, TAlertTitleProps>(({ className, ...props }, ref) => (
    <h5 ref={ref} className={cn("mb-1 font-medium leading-none tracking-tight", className)} {...props} />
));
AlertTitle.displayName = "AlertTitle";

type TAlertDescriptionProps = React.HTMLAttributes<HTMLParagraphElement>;

const AlertDescription: React.FC<TAlertDescriptionProps> = React.forwardRef<HTMLParagraphElement, TAlertDescriptionProps>(
    ({ className, ...props }, ref) => <div ref={ref} className={cn("text-sm [&_p]:leading-relaxed font-normal", className)} {...props} />
);
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription, AlertTitle };
