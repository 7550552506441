import React from "react";

import { cn } from "../lib/utils";

export const TagIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
    <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={cn("inline-block", props.className)}
    >
        <g id="IconDiscount">
            <path
                id="Symbol"
                d="M12.3123 18.0394C12.0052 18.3465 11.6247 18.5 11.1708 18.5C10.7169 18.5 10.3365 18.3465 10.0294 18.0394L2.9806 10.9906C2.83375 10.8438 2.71694 10.6702 2.63016 10.47C2.54339 10.2697 2.5 10.0561 2.5 9.82916V4.102C2.5 3.66145 2.65686 3.28431 2.97059 2.97059C3.28431 2.65686 3.66145 2.5 4.102 2.5H9.82916C10.0561 2.5 10.2697 2.54339 10.47 2.63016C10.6702 2.71694 10.8438 2.83375 10.9906 2.9806L18.0394 10.0494C18.3465 10.3565 18.5 10.7336 18.5 11.1809C18.5 11.6281 18.3465 12.0052 18.0394 12.3123L12.3123 18.0394ZM6.10451 7.30601C6.43826 7.30601 6.72194 7.18919 6.95557 6.95557C7.18919 6.72194 7.30601 6.43826 7.30601 6.10451C7.30601 5.77076 7.18919 5.48707 6.95557 5.25344C6.72194 5.01982 6.43826 4.903 6.10451 4.903C5.77076 4.903 5.48707 5.01982 5.25344 5.25344C5.01982 5.48707 4.903 5.77076 4.903 6.10451C4.903 6.43826 5.01982 6.72194 5.25344 6.95557C5.48707 7.18919 5.77076 7.30601 6.10451 7.30601Z"
                fill="#23B33D"
            />
        </g>
    </svg>
);
