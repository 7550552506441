import { appTypes } from "@app/app";
import { ControlledFormField, CountInputField } from "@app/common";
import { propertyTypes } from "@app/property";
import { roomUtils } from "@app/room";
import { UtilsIdentifier } from "@hotelchamp/common";
import { Button, cn, Flex, Text, Title, TrashIcon } from "@ui";
import { Fragment } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface IRoomsConfiguratorSectionProps {
    propertyId?: string;
    property?: propertyTypes.IProperty;
    bookingState: appTypes.IBookingState;
    control: Control<appTypes.IBookingEngineState, any>;
}

/**
 * RoomsConfiguratorSection
 */
export const RoomsConfiguratorSection = ({ propertyId, property, bookingState, control }: IRoomsConfiguratorSectionProps) => {
    const propertyMaxRoomOccupancy = property ? roomUtils.propertyMaxRoomOccupancy(property) : 10;
    const { t } = useTranslation(["availability", "common"]);
    const { fields, append, remove } = useFieldArray({
        control,
        name: "bookingState.bookings",
    });
    const childrenCategory = property?.child_categories.find((cc) => cc.name === "children");

    const handleAddRoomClick = () => {
        append({
            id: UtilsIdentifier.uniqueString(),
            adultsCount: 2,
            childCount: 0,
            infantCount: 0,
            room: null,
            rate: null,
            package: null,
            extras: [],
            type: "room",
            isConfirmed: false,
            price: undefined,
            discountedPrice: undefined,
        });
    };

    return (
        <Fragment>
            {fields.map((field, index) => (
                <div data-testid="guest-selection" key={field.id}>
                    <Flex content="center" justifyContent="between" alignItems="center" className="flex-none mb-4 h-9">
                        <Flex justifyContent="start" className="gap-x-4">
                            <Text data-testid="room-number" className="flex uppercase text-xs font-bold tracking-wide text-gray-900">
                                {t("search.room") + " " + (index + 1)}
                            </Text>
                        </Flex>

                        {fields.length > 1 && (
                            <Flex justifyContent="end">
                                <Button
                                    data-testid={`remove-room-${index}-btn`}
                                    icon={TrashIcon}
                                    variant="ghost"
                                    size="sm"
                                    className="text-gray-600 font-medium"
                                    iconPosition="right"
                                    onClick={() => remove(index)}
                                >
                                    {t("remove", { ns: "common" })}
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                    <div className="space-y-4">
                        <Flex data-testid={`room-${index}-adults-count`}>
                            <Title className="text-base font-normal text-gray-500">{t("adults", { ns: "common" })}</Title>
                            <ControlledFormField
                                name={`bookingState.bookings.${index}.adultsCount`}
                                control={control}
                                defaultValue={2}
                                minValue={1}
                                maxValue={propertyMaxRoomOccupancy}
                                component={CountInputField}
                            />
                        </Flex>
                        <Flex data-testid={`room-${index}-child-count`}>
                            <Flex flexDirection="col" justifyContent="start" alignItems="start">
                                <Title className="text-base font-normal text-gray-500">{t("children", { ns: "common" })}</Title>
                                <Text className="text-xs text-gray-400">
                                    {t("ages", { ns: "common" })} {childrenCategory?.from || 2} - {childrenCategory?.till || 12}
                                </Text>
                            </Flex>
                            <ControlledFormField
                                name={`bookingState.bookings.${index}.childCount`}
                                control={control}
                                defaultValue={2}
                                minValue={0}
                                maxValue={propertyMaxRoomOccupancy}
                                component={CountInputField}
                            />
                        </Flex>
                    </div>
                    <div className={cn("mt-6 mb-4", fields.length !== index + 1 ? "border-t" : "")} />
                </div>
            ))}
            <Button data-testid="add-room" variant="outline" className="w-full mt-3 mb-3" onClick={handleAddRoomClick}>
                {t("search.addRoom")}
            </Button>
        </Fragment>
    );
};
