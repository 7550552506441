import { defineQueryKeyCreator } from "../utils/queryUtils";

const define = defineQueryKeyCreator("exchanges");

export const QueryKeys = {
    ExchangeRateItems: define("exchange-rate-items"),
    CurrencyItems: define("currency-items"),
    LanguageItems: define("language-items"),
    PromoDiscount: define("promo-discount"),
    Translations: define("translations"),
} as const;
