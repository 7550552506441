import { ComponentType, lazy } from "react";

export type TImportStatement = () => Promise<{ default: ComponentType<any> }>;

/**
 * reactLazyPreload
 * @more - https://blog.maximeheckel.com/posts/preloading-views-with-react/
 */
export const reactLazyPreload = (importStatement: TImportStatement) => {
    const Component = lazy(importStatement);

    return Object.assign(Component, { preload: importStatement });
};
