import React from "react";
import { useQuery } from "react-query";

import { QueryKeys } from "./constants/QueryKeys";
import { getExchangeRates, getPromoDiscount, getTranslations } from "./queries";
import { IExchangeRates, IPromoDiscount, ITranslation, IUseGetOptions } from "./types";

/**
 * useGetExchanges
 */
export const useGetExchangeRates = (propertyId: string, options?: IUseGetOptions) =>
    useQuery<IExchangeRates, Error>([QueryKeys.ExchangeRateItems], () => getExchangeRates(propertyId), options);

/**
 * useGetPromoDiscount
 */
export const useGetPromoDiscount = (code: string, propertyId: string, options?: IUseGetOptions) =>
    useQuery<IPromoDiscount, Error>([QueryKeys.PromoDiscount, propertyId, code], () => getPromoDiscount(code, propertyId), {
        ...options,
        retry: false,
    });

/**
 * useGetTranslations
 */
export const useGetTranslations = (propertyId: string, options?: IUseGetOptions) =>
    useQuery<ITranslation, Error>([QueryKeys.Translations], () => getTranslations(propertyId), options);

export const useForwardedRef = <T extends {}>(ref: React.ForwardedRef<T>): React.MutableRefObject<T | null> => {
    const innerRef = React.useRef(null);

    React.useEffect(() => {
        if (!ref) {
            return;
        }

        if (typeof ref === "function") {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef as React.MutableRefObject<T | null>;
};
