import { css } from "twind/css";

export const styles = css({
    ":global": {
        ":root": {
            "--hc-background": "0 0% 98%",
            "--hc-foreground": "0 0% 9%",

            "--hc-card": "0 0% 98%",
            "--hc-card-foreground": "0 0% 21%",

            "--hc-popover": "0 0% 100%",
            "--hc-popover-foreground": "222.2 84% 4.9%",

            "--hc-primary": "var(---hc-primary)",
            "--hc-primary-foreground": "0 0% 100%",

            "--hc-secondary": "var(---hc-secondary)",
            "--hc-secondary-foreground": "var(---hc-secondary-foreground)",

            "--hc-muted": "0 0% 95%",
            "--hc-muted-foreground": "0 0% 21%",

            "--hc-accent": "0 0% 98% 1" /* used for badge */,
            "--hc-accent-foreground": "0 0% 32%" /* used for badge */,

            "--hc-destructive": "0 84.2% 60.2%",
            "--hc-destructive-foreground": "210 40% 98%",

            "--hc-border": "0 0% 78%, 1",
            "--hc-input": "214.3 31.8% 91.4%",
            "--hc-ring": "222.2 84% 4.9%",
        },
        ".dark": {
            "--hc-background": "222.2 84% 4.9%",
            "--hc-foreground": "210 40% 98%",

            "--hc-card": "222.2 84% 4.9%",
            "--hc-card-foreground": "210 40% 98%",

            "--hc-popover": "222.2 84% 4.9%",
            "--hc-popover-foreground": "210 40% 98%",

            "--hc-primary": "210 40% 98%",
            "--hc-primary-foreground": "0, 0%, 100%, 1",

            "--hc-secondary": "217.2 32.6% 17.5%",
            "--hc-secondary-foreground": "210 40% 98%",

            "--hc-muted": "217.2 32.6% 17.5%",
            "--hc-muted-foreground": "215 20.2% 65.1%",

            "--hc-accent": "217.2 32.6% 17.5%",
            "--hc-accent-foreground": "210 40% 98%",

            "--hc-destructive": "0 62.8% 30.6%",
            "--hc-destructive-foreground": "210 40% 98%",

            "--hc-border": "217.2 32.6% 17.5%",
            "--hc-input": "217.2 32.6% 17.5%",
            "--hc-ring": "212.7 26.8% 83.9%",
        },
        "*": {
            "@apply": "border-border",
            "font-feature-settings": "'ss04' on",
            "-webkit-font-smoothing": "antialiased",
            "-moz-osx-font-smoothing": "grayscale",
        },
        html: {
            "font-family": '"DM Sans", system-ui, sans-serif',
        },
        body: {
            "@apply": "bg-background",
            "-webkit-overflow-scrolling": "auto",
        },
    },
});
