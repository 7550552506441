import { axios } from "@app/app";

import { IExchangeRates, IPromoDiscount, ITranslation } from "./types";

export const getExchangeRates = async (propertyId: string): Promise<IExchangeRates> => {
    const response = await axios.get(`properties/${propertyId}/exchanges`);

    return response.data;
};

export const getPromoDiscount = async (code: string, propertyId: string): Promise<IPromoDiscount> => {
    const response = await axios.get(`properties/${propertyId}/promos?code=${code}`);

    return response.data;
};

export const getTranslations = async (propertyId: string): Promise<ITranslation> => {
    const response = await axios.get(`properties/${propertyId}/translations`);

    return response.data;
};
