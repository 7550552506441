import { ErrorBoundaryProvider } from "@app/app/context/ErrorBoundaryProvider";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "../ErrorFallback";
import { RouterContext } from "./RouterContext";
import { findMatchingChildRoute, preloadPath } from "./utils";

export interface IOutletProps {
    context?: unknown;
}

const EMPTY_CHILDREN: React.ReactNode[] = [];

// Custom Outlet component
export const Outlet = React.forwardRef(({}, ref: any) => {
    const { el, setData, routesRootElement } = React.useContext(RouterContext);

    const matchingChildRoute = findMatchingChildRoute(el?.props.children || EMPTY_CHILDREN, window.location.search);
    const nextData = matchingChildRoute?.props.data;

    React.useEffect(() => {
        if (nextData) {
            setData(nextData);

            if (nextData.next) {
                preloadPath(nextData.next, routesRootElement);
            }

            if (nextData.back) {
                preloadPath(nextData.back, routesRootElement);
            }
        }
    }, [nextData]);

    if (!matchingChildRoute) {
        return null;
    }

    return (
        <ErrorBoundaryProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>{matchingChildRoute.props.element}</ErrorBoundary>
        </ErrorBoundaryProvider>
    );
});
