import { commonTypes } from "@app/common";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue, TSelectProps } from "@ui";
import React from "react";

export interface ILanguageSelectProps extends TSelectProps {
    languages: commonTypes.ILanguage[];
    onChange?: (value: string) => void;
    placeholder?: string;
}

export const LanguageSelect = React.forwardRef(
    ({ languages, placeholder, onChange, ...selectProps }: ILanguageSelectProps, ref: React.ForwardedRef<unknown>) => (
        <Select {...selectProps} onValueChange={onChange}>
            <SelectTrigger className="">
                <SelectValue placeholder={placeholder || "Select language"} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {languages.map((language) => (
                        <SelectItem data-testid={`language-item-${language.code}`} key={language.code} value={language.code}>
                            {language.label}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
);
