import React from "react";

export interface IRouteData {
    label: string;
    back: string;
    next: string;
}

export interface IRouteProps {
    children?: React.ReactElement<IRouteProps> | Array<React.ReactElement<IRouteProps>>;
    element: React.ReactElement;
    path: string;
    data?: IRouteData;
}

export interface IRouteContextObject {
    outlet: React.ReactElement | null;
}

export const RouteContext = React.createContext<IRouteContextObject>({
    outlet: null,
});

export const Route = ({ path, element, children }: IRouteProps) => (element as JSX.Element) || (children as JSX.Element);
