import nativeAxios from "axios";

import { config } from "../config";

export const WsServiceClient = nativeAxios.create({
    baseURL: config.wsServicesApiUrl,
    withCredentials: false,
    // Disabled because server side headers
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});
