import { tremorTwMerge } from "@app/componentLibrary";
import React from "react";

export const LoadingDots = (props: React.HTMLAttributes<SVGSVGElement>) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={tremorTwMerge("inline-block fill-black", props.className)}
    >
        <circle className="animate-bounce opacity-40" cx="4" cy="12" r="3" fill="fillColor" />
        <circle className="animate-bounce opacity-40" cx="12" cy="12" r="3" fill="fillColor" style={{ animationDelay: "0.1s" }} />
        <circle className="animate-bounce opacity-40" cx="20" cy="12" r="3" fill="fillColor" style={{ animationDelay: "0.2s" }} />
    </svg>
);
