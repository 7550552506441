import { axios } from "@app/app";

import { IBookingRequest, IBookingResponse, ICancelBookingRequest, ICancelBookingResponse, ICart, IReservedBooking } from "./types";

export const getCart = async (propertyId: string, searchQuery: string): Promise<ICart> => {
    const response = await axios.get(`properties/${propertyId}/cart?${searchQuery}`);

    return response.data;
};

export const createBooking = async (data: IBookingRequest): Promise<IBookingResponse> => {
    const response = await axios.post(`properties/${data.pi}/bookings`, data);

    return response.data;
};

export const updateBooking = async (bookingId: string, data: IBookingRequest): Promise<IBookingResponse> => {
    const response = await axios.put(`properties/${data.pi}/bookings/${bookingId}`, data);

    return response.data;
};

export const cancelBooking = async (data: ICancelBookingRequest): Promise<ICancelBookingResponse> => {
    const response = await axios.post(`properties/${data.property_id}/bookings/cancel`, data);

    return response.data;
};

export const getReservedBooking = async (propertyId: string, bookingId: string): Promise<IReservedBooking> => {
    const response = await axios.get(`properties/${propertyId}/bookings/${bookingId}`);

    return response.data;
};
