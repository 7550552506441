import { StorageManager, StorageTypes } from "@app/common";
import { SessionStorage, Storage, StorageAdapterLocalStorage, TimeUnits } from "@hotelchamp/common";

import { resolvePropertyIdOrFail } from "../utils/appUtils";

export const storageManager = new StorageManager();

const generateScopeRootKey = (rootKey: string) => `${resolvePropertyIdOrFail()}.${rootKey}`;

storageManager.registerByType(
    StorageTypes.Global,
    new Storage({
        adapter: new StorageAdapterLocalStorage({
            rootKey: generateScopeRootKey("__hc_ibe__.local"),
        }),
    })
);

storageManager.registerByType(
    StorageTypes.GlobalSession,
    new SessionStorage({
        adapter: new StorageAdapterLocalStorage({
            rootKey: generateScopeRootKey("__hc_ibe__.session"),
        }),
        expire: TimeUnits.Minute * 30,
        sessionStorageStateKey: StorageTypes.GlobalSession,
    })
);

storageManager.registerByType(
    StorageTypes.ExtendedGlobalSession,
    new SessionStorage({
        adapter: new StorageAdapterLocalStorage({
            rootKey: generateScopeRootKey("__hc_ibe__.ext_session"),
        }),
        expire: TimeUnits.Week,
        sessionStorageStateKey: StorageTypes.ExtendedGlobalSession,
    })
);
