import { storageManager } from "@app/app";
import { Dialog, DialogHeader, DialogHeightModes, DialogVerticalPositions } from "@app/common";
import { Button, Text } from "@ui";
import { Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { appTypes } from "..";
import { useBookingEngineStateContext, useCustomNavigate } from "../hooks";
import { AvailabilityWarningKey } from "../types";

// Availability check Scenarios: The checks are processed by the BookingEngineStateProvider.
// 1- Selected rate unavailable
// 2- Selected room unavailable
// 3- Selected dates unavailable
// 4- Change in price of previously selected rate

export const BookingCheckWarning = () => {
    const { t } = useTranslation("booking");
    const [warningKey, setWarningKey] = useState<AvailabilityWarningKey | null>(null);
    const [redirectTo, setRedirectTo] = useState<string>("");
    const [acknowledged, setAcknowledged] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useCustomNavigate();
    const { getState, resetBooking } = useBookingEngineStateContext();
    const { setValue } = useFormContext<appTypes.IBookingEngineState>();
    const state = getState();
    const isActiveWarning = !!state.activeWarningKey;
    const activeStep = state.activeStep;
    const isConfirmationPage = activeStep === "thank-you";

    const handleAcceptClick = () => {
        if (redirectTo.includes("search")) {
            resetBooking();
        }

        setOpen(false);

        setAcknowledged(true);

        setValue("activeWarningKey", null);

        if (state.activeWarningKey?.includes("price")) {
            storageManager.getExtendedGlobalSession().set("__hc__ibe_booking_check_ack__", true);
        }

        redirectTo && navigate(redirectTo);
    };

    const handleCloseClick = () => {
        setOpen(false);
    };

    useEffect(() => {
        storageManager
            .getExtendedGlobalSession()
            .get("__hc__ibe_booking_check_ack__")
            .then((ack: boolean) => {
                if (ack !== null) {
                    setAcknowledged(ack);
                }
            });
    }, []);

    useEffect(() => {
        if (isActiveWarning) {
            setWarningKey(state.activeWarningKey);

            switch (state.activeWarningKey) {
                case "date":
                    setRedirectTo("?p=base&c=search");
                    break;
                case "rate":
                    setRedirectTo("?p=base&c=item");
                    break;
                case "room":
                    setRedirectTo("?p=base&c=availability");
                    break;
                default:
                    setRedirectTo("");
            }

            setOpen(true);
        }
    }, [isActiveWarning]);

    return !acknowledged && !isConfirmationPage ? (
        <Dialog verticalPosition={DialogVerticalPositions.Middle} heightMode={DialogHeightModes.Fit} forcedOpen={open}>
            {({ close }) => (
                <Fragment>
                    <DialogHeader onClose={handleCloseClick}>{t("validity.modalTitle")}</DialogHeader>

                    <div className="p-6 w-full max-h-fit">
                        <Text>{t(`validity.availabilityCheckWarning.${warningKey}.msg`)}</Text>

                        <Button data-testid="booking-validator-action-btn" className="w-full mt-6" onClick={handleAcceptClick}>
                            {t(`validity.availabilityCheckWarning.${warningKey}.btn`)}
                        </Button>
                    </div>
                </Fragment>
            )}
        </Dialog>
    ) : null;
};
