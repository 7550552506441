import { cn } from "@ui";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const badgeVariants = cva(
    "inline-flex items-center rounded-lg text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 whitespace-nowrap",
    {
        variants: {
            variant: {
                default: "bg-primary text-white hover:bg-primary/80",
                secondary: "border-primary border-1 bg-secondary text-primary hover:bg-secondary/80 border-primary",
                destructive: "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
                muted: "border border-gray-200 text-gray-700 bg-gray-50",
                outline: "border border-gray-250 text-gray-600",
            },
            size: {
                sm: "px-1 py-0.5",
                md: "px-2 py-0.5 h-6",
                lg: "p-2",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "md",
        },
    }
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
    icon?: React.ElementType;
    children?: React.ReactNode | React.ReactNode[];
}

function Badge({ className, variant, size, icon, children, ...props }: BadgeProps) {
    const Icon = icon;
    const showIcon = Icon !== undefined;

    return (
        <div className={cn(badgeVariants({ variant, size }), className)} {...props}>
            {showIcon ? <Icon className="w-3.5 mr-1" /> : null}
            {children}
        </div>
    );
}

export { Badge, badgeVariants };
