import { HorizontalPosition, VerticalPosition } from "./inputTypes";

export const HorizontalPositions: { [key: string]: HorizontalPosition } = {
    Left: "left",
    Right: "right",
};

export const VerticalPositions: { [key: string]: VerticalPosition } = {
    Top: "top",
    Bottom: "bottom",
};
