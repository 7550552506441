import { axios } from "@app/app";

import { ICheckoutSessionState } from "./types";

export const upsertCheckoutSession = async (
    propertyId: string,
    searchQuery: string,
    state?: ICheckoutSessionState | undefined
): Promise<ICheckoutSessionState> => {
    const response = await axios.post<ICheckoutSessionState>(`/properties/${propertyId}/checkout/checkout-session?${searchQuery}`, state);

    return response.data;
};
