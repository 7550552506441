import { cn } from "@ui";
import React from "react";

export interface ILabelProps extends React.HTMLAttributes<HTMLLabelElement> {
    children?: React.ReactNode | React.ReactNode[];
    htmlFor?: string;
}

export const Label = ({ children, ...labelProps }: ILabelProps) => (
    <label {...labelProps} className={cn("inline-block text-neutral-900 text-base font-normal leading-none mb-2", labelProps.className)}>
        {children}
    </label>
);
