import { commonTypes } from "@app/common";
import { propertyTypes } from "@app/property";

export const convertItemPrice = (
    price: commonTypes.IPrice,
    targetCurrency: string,
    exchangeRates: commonTypes.IExchangeRates
): commonTypes.IPrice => ({
    original: convertCurrency(exchangeRates, price.original, targetCurrency),
    discounted: price.discounted ? convertCurrency(exchangeRates, price.discounted, targetCurrency) : price.discounted,
});

export const convertCurrency = (exchangeRates: commonTypes.IExchangeRates, value: number, targetCurrency: string) => {
    if (!exchangeRates.rates) {
        return value;
    }
    const exchangeRate = exchangeRates?.rates[targetCurrency];

    return exchangeRate ? value * exchangeRate : value;
};

export const propertyMaxRoomOccupancy = (property: propertyTypes.IProperty): number =>
    property.rooms.reduce((max, room) => (room.max_occupancy > max ? room.max_occupancy : max), 0);
