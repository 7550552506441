// import { TrackingProperties } from "@app/tracking";
import {
    AdobeAnalyticsContracts,
    AnalyticsAdapterAdobeAnalytics,
    AnalyticsAdapterChain,
    AnalyticsAdapterContract,
    AnalyticsAdapterGoogleAnalytics,
    AnalyticsAdapterMemory,
    GoogleAnalyticsVersions,
} from "@hotelchamp/common";

interface IAnalyticsAdapterConfiguratorConfig {
    googleAnalytics?: IAnalyticsAdapterConfiguratorGoogleAnalyticsConfig;
    adobeAnalytics?: IAnalyticsAdapterConfiguratorAdobeAnalyticsConfig;
}

interface IAnalyticsAdapterConfiguratorGoogleAnalyticsConfig {
    dimension?: number; // @deprecated; use customMap instead
    customMap?: {
        // custom dimension mapping between index and name
        [key: string]: number;
    };
    configuredGaVersion?: GoogleAnalyticsVersions;
}

interface IAnalyticsAdapterConfiguratorAdobeAnalyticsConfig {
    mapping: AdobeAnalyticsContracts.INameToIndexMapping;
    reportSuite: string;
    props?: string[]; // additional props which will by applied initially to all events
}

/**
 * AnalyticsAdapter configurator
 */

export const analyticsAdapterConfigurator = (config: IAnalyticsAdapterConfiguratorConfig) => {
    const adapters: AnalyticsAdapterContract[] = [];

    if (process.env.APP_MODE === "toolbuilder" || process.env.NODE_ENV !== "production") {
        if (config.googleAnalytics) {
            const gaClient = getGoogleAnalytics4Client(config.googleAnalytics);

            adapters.push(gaClient);
        } else {
            const memoryAdapter = new AnalyticsAdapterMemory();

            adapters.push(memoryAdapter);

            if (process.env.NODE_ENV !== "production") {
                (window as any).analyticsTrackingRecords = memoryAdapter.getRecords();
            }
        }
    } else {
        if (config.googleAnalytics) {
            adapters.push(getGoogleAnalytics4Client(config.googleAnalytics));
        }

        if (config.adobeAnalytics) {
            const adobeAnalyticsAdapter = getAdobeAnalyticsClient(config.adobeAnalytics);
            const adobeAnalyticsProps = config.adobeAnalytics.props;

            adapters.push(adobeAnalyticsAdapter);

            if (adobeAnalyticsProps) {
                adobeAnalyticsAdapter.getClient().then((client) => {
                    adobeAnalyticsProps.forEach((prop: any) => client.set(prop));

                    client.sendSetProps();
                });
            }
        }
    }

    return new AnalyticsAdapterChain(adapters);
};

/**
 * getGoogleAnalytics4Client
 * Helper which knows how to configure a GoogleAnalyticsClient
 */
const getGoogleAnalytics4Client = ({ dimension, customMap, configuredGaVersion }: IAnalyticsAdapterConfiguratorGoogleAnalyticsConfig) => {
    const customDimensionMap =
        customMap ||
        {
            // [TrackingProperties.TestingGroup]: dimension,
        };

    // return new HotelchampGoogleAnalyticsAdapter({
    return new AnalyticsAdapterGoogleAnalytics({
        configuredGaVersion,
        gtagConfig: {
            // debug: true,
            trackerBlacklist: [],
        },
        gtmConfig: {
            trackerBlacklist: [],
        },
        universalConfig: {
            customDimensionMap,
            trackerBlacklist: [],
        },
    });
};

/**
 * getAdobeAnalyticsClient
 * Helper which knows how to configure a AdobeAnalyticsClient
 */
const getAdobeAnalyticsClient = (config: IAnalyticsAdapterConfiguratorAdobeAnalyticsConfig) =>
    new AnalyticsAdapterAdobeAnalytics({
        dtmConfig: {
            client: {
                mapping: config.mapping,
                reportSuite: config.reportSuite,
            },
        },
    });
