import { tremorTwMerge } from "@app/componentLibrary";
import React from "react";

export const FullScreenIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={tremorTwMerge("inline-block", props.className)}
    >
        <g id="IconFullScreen">
            <mask id="mask0_2407_302" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
                <rect id="Bounding box" width="14" height="14" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2407_302)">
                <path
                    id="open_in_full"
                    d="M2.33333 12.25C2.16806 12.25 2.02951 12.1941 1.91771 12.0823C1.8059 11.9705 1.75 11.8319 1.75 11.6667V8.16667C1.75 8.00139 1.8059 7.86285 1.91771 7.75104C2.02951 7.63924 2.16806 7.58333 2.33333 7.58333C2.49861 7.58333 2.63715 7.63924 2.74896 7.75104C2.86076 7.86285 2.91667 8.00139 2.91667 8.16667V10.2667L10.2667 2.91667H8.16667C8.00139 2.91667 7.86285 2.86076 7.75104 2.74896C7.63924 2.63715 7.58333 2.49861 7.58333 2.33333C7.58333 2.16806 7.63924 2.02951 7.75104 1.91771C7.86285 1.8059 8.00139 1.75 8.16667 1.75H11.6667C11.8319 1.75 11.9705 1.8059 12.0823 1.91771C12.1941 2.02951 12.25 2.16806 12.25 2.33333V5.83333C12.25 5.99861 12.1941 6.13715 12.0823 6.24896C11.9705 6.36076 11.8319 6.41667 11.6667 6.41667C11.5014 6.41667 11.3628 6.36076 11.251 6.24896C11.1392 6.13715 11.0833 5.99861 11.0833 5.83333V3.73333L3.73333 11.0833H5.83333C5.99861 11.0833 6.13715 11.1392 6.24896 11.251C6.36076 11.3628 6.41667 11.5014 6.41667 11.6667C6.41667 11.8319 6.36076 11.9705 6.24896 12.0823C6.13715 12.1941 5.99861 12.25 5.83333 12.25H2.33333Z"
                    fill="fillColor"
                />
            </g>
        </g>
    </svg>
);
