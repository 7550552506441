import { appTypes } from "@app/app";
import { roomUtils } from "@app/room";

import { commonTypes } from "..";

interface IGetRateBreakdownParams {
    bookingEngineState: appTypes.IBookingEngineState;
    los: number;
    booking?: appTypes.IBooking;
    adultCount?: number;
    childCount?: number;
    infantCount?: number;
    priceableItem: commonTypes.TPriceableItem | number | undefined;
    promoDiscount?: commonTypes.IPromoDiscount;
    forcedDiscountedPrice?: number;
    exchangeRates: commonTypes.IExchangeRates;
}

export const getRateBreakdown = ({
    bookingEngineState,
    priceableItem,
    promoDiscount,
    los,
    exchangeRates,
    forcedDiscountedPrice,
    adultCount = 0,
    childCount = 0,
    infantCount = 0,
}: IGetRateBreakdownParams) => {
    const { property, currency: targetCurrency = "EUR" } = bookingEngineState;
    const isPricePerNight = !!property?.ui_config.show_rate_per_night;
    const isPriceableItemNumeric = typeof priceableItem === "number";
    const baseCurrency = property?.settings.source_currency || "EUR";

    const priceableItemInstance = isPriceableItemNumeric
        ? { original: priceableItem, discounted: Number(forcedDiscountedPrice) < priceableItem ? forcedDiscountedPrice : undefined } // Ensure we have a valid discounted price
        : priceableItem && adultCount
        ? { original: priceableItem?.min_rate, discounted: undefined } // TODO: add discounted price in backend
        : undefined;

    const exchangedItemPrice = priceableItemInstance
        ? roomUtils.convertItemPrice(priceableItemInstance, targetCurrency, exchangeRates)
        : null;

    const breakdown = {
        baseCurrency,
        targetCurrency,
        baseRate: priceableItemInstance?.original,
        discountedRate: priceableItemInstance?.discounted,
        exchangedBaseRate: exchangedItemPrice?.original,
        exchangedDiscountedRate: exchangedItemPrice?.discounted,
        exchangeRate: exchangeRates?.rates ? exchangeRates.rates[targetCurrency] : baseCurrency,
        locale: bookingEngineState.language,
    };

    return breakdown;
};
