import { DocumentObserverEventTypes } from "@hotelchamp/common";
import { BrowserHistory, Location } from "history";
import React, { useContext, useEffect, useState } from "react";

import { documentObserver } from "../../services/documentObserver";
import { history } from "../../services/history";
import { IRouteData, IRouteProps } from "./Route";
import { IRoute, locationToRoute } from "./utils";

export interface IRouterContext {
    route: IRoute;
    history: BrowserHistory;
    data: IRouteData;
    routesRootElement: React.ReactElement<IRouteProps> | Array<React.ReactElement<IRouteProps>>;
    el: any | undefined;
    setData: (data: Partial<IRouteData>) => void;
}

export const RouterContext = React.createContext({} as IRouterContext);

interface IRouterProviderProps {
    children: React.ReactElement<IRouteProps> | Array<React.ReactElement<IRouteProps>>;
}

export const RouterProvider = ({ children }: IRouterProviderProps) => {
    const [route, setRoute] = useState(locationToRoute(history.location));
    const [data, setData] = useState<any>();
    const [el, setEl] = useState<any>();

    const handleRouteChange = (location: Location) => {
        const r = locationToRoute(location);

        setRoute(r);
    };

    useEffect(() => {
        const documentObserverDetacher = documentObserver.on(DocumentObserverEventTypes.UrlChange, handleUrlChange);

        const historyListenerDetacher = history.listen(handleUrlChange);

        return () => {
            documentObserverDetacher();
            historyListenerDetacher();
        };
    }, []);

    // Find the first child that matches the current route
    const matchingChild = React.Children.toArray(children).find((child) => {
        if (!React.isValidElement(child)) {
            return false;
        }

        const routeQuery = `?p=${route.query.p}`;
        const childQuery = child.props.path;

        if (!child.props.path) {
            throw new Error("RouterProvider - Route must have a path prop!");
        }

        return routeQuery === childQuery;
    });

    const handleUrlChange = () => {
        handleRouteChange(window.location as unknown as Location);
    };

    useEffect(() => {
        if (matchingChild && React.isValidElement(matchingChild)) {
            if (!data) {
                setData(matchingChild.props.data);
            }

            if (matchingChild.props.children) {
                setRoute({ ...route, children: matchingChild.props.children });
            }
        }
    }, [route.path]);

    useEffect(() => {
        if (matchingChild && React.isValidElement(matchingChild) && !el) {
            setEl(matchingChild);

            if (matchingChild.props.onLoad && typeof matchingChild.props.onLoad === "function") {
                matchingChild.props.onLoad();
            }
        }
    }, [matchingChild, el]);

    return (
        <RouterContext.Provider value={{ route, history, data, el, setData, routesRootElement: children }}>
            {!matchingChild ? <React.Fragment /> : matchingChild}
        </RouterContext.Provider>
    );
};

export const useRouter = () => useContext(RouterContext);
