import { type Stripe } from "@stripe/stripe-js";
import React from "react";

export interface IStripeContext {
    stripe?: Stripe;
    stripePromise?: Promise<Stripe | null>;
    isInitialized: boolean;
}

export const StripeContext = React.createContext<IStripeContext>({
    isInitialized: false,
});

StripeContext.displayName = "StripeContext";
