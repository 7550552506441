import { cn } from "@ui";
import React from "react";

export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {}

const Text: React.FC<TextProps> = React.forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
    const { className, ...restProps } = props;

    return <p ref={ref} className={cn("text-sm font-normal text-gray-500", className)} {...restProps} />;
});

Text.displayName = "Text";

export default Text;
