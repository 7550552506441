import { reactLazyPreload } from "@app/common";
import { Location } from "history";
import qs from "querystringify";

import { log } from "../../services/log";
import { IRouteProps } from "./Route";

export interface IRoute {
    path: string;
    hash: string;
    query: { [key: string]: string };
    children: React.ReactNode[];
}

export function locationToRoute(location: Location): IRoute {
    return {
        path: location.pathname,
        hash: location.hash,
        query: qs.parse(location.search) as { [key: string]: string },
        children: [],
    };
}

const preloadedRoutePaths: string[] = [];

export const preloadPath = (path: string, routesRootElement: React.ReactElement<IRouteProps> | Array<React.ReactElement<IRouteProps>>) => {
    if (path && !preloadedRoutePaths.includes(path)) {
        const nextMatchingChildRoute = findMatchingChildRoute(routesRootElement || [], path);

        if (nextMatchingChildRoute) {
            const preloadFn = (nextMatchingChildRoute?.props?.element.type as ReturnType<typeof reactLazyPreload>)?.preload;

            if (preloadFn) {
                log.debug("Preload route", path);

                preloadedRoutePaths.push(path);

                preloadFn();
            }
        }
    }
};

export const findMatchingChildRoute = (
    children: Array<React.ReactElement<IRouteProps>> | React.ReactElement<IRouteProps>,
    search: string
): React.ReactElement<IRouteProps> | undefined => {
    const params = new URLSearchParams(search);
    const parentRoute = params.get("p");
    const childRoute = params.get("c");

    const processableChildren = Array.isArray(children) ? children : [children];

    for (let i = 0; i < processableChildren.length; i++) {
        const child = processableChildren[i];
        const isMatch = `?p=${parentRoute}&c=${childRoute}` === child.props.path;

        if (isMatch) {
            return child;
        } else if (!isMatch && child.props.children) {
            return findMatchingChildRoute(child.props.children, search);
        }
    }
};
