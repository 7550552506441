import { Dialog, DialogHeader, DialogHeightModes, DialogVerticalPositions } from "@app/common";
import { UtilsUrl } from "@hotelchamp/common";
import * as Sentry from "@sentry/react";
import { Text } from "@ui";
import { Fragment, useEffect, useState } from "react";

import { useBookingEngineStateContext } from "../hooks";

export const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
    const [isOpen, setIsOpen] = useState(true);
    const { resetBooking } = useBookingEngineStateContext();

    useEffect(() => {
        // Log error to Sentry
        Sentry.captureException(error);
    }, []);

    return (
        <Dialog verticalPosition={DialogVerticalPositions.Middle} heightMode={DialogHeightModes.Fit} forcedOpen={isOpen}>
            {({ close }) => (
                <Fragment>
                    <DialogHeader
                        onClose={() => {
                            const nextUrl = UtilsUrl.setUrlParams(document.location.href, { p: "base", c: "search" });

                            resetBooking();
                            setIsOpen(false);

                            window.history.pushState({}, "", nextUrl);

                            setTimeout(resetErrorBoundary, 300);
                        }}
                    >
                        Something went wrong
                    </DialogHeader>

                    <div className="p-6 w-full max-h-fit">
                        <Text className="bg-white p-6">Something went wrong, please try again later!</Text>
                    </div>
                </Fragment>
            )}
        </Dialog>
    );
};
