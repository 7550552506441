import { propertyTypes } from "@app/property";
import { LanguageDetection } from "@hotelchamp/common";

const languageCodeMapping: { [key: string]: string } = {
    en: "en-US",
    nl: "nl-NL",
    fr: "fr-FR",
    de: "de-DE",
    es: "es-ES",
    it: "it-IT",
    pt: "pt-PT",
    pl: "pl-PL",
    sv: "sv-SE",
    da: "da-DK",
    fi: "fi-FI",
    no: "no-NO",
    ru: "ru-RU",
    tr: "tr-TR",
    ar: "ar-SA",
    hi: "hi-IN",
    bn: "bn-IN",
    id: "id-ID",
    ja: "ja-JP",
    ko: "ko-KR",
    th: "th-TH",
    vi: "vi-VN",
    zh: "zh-CN",
    he: "he-IL",
    ro: "ro-RO",
    sk: "sk-SK",
    cs: "cs-CZ",
    hu: "hu-HU",
    el: "el-GR",
    ta: "ta-IN",
};

export const formatLanguageCode = (unformattedLanguageCode: string) => {
    try {
        return new Intl.Locale(unformattedLanguageCode).toString();
    } catch (error) {
        return unformattedLanguageCode;
    }
};
export const mapDetectedLanguage = (detectedLanguageCode: string) => {
    const formattedLanguageCode = formatLanguageCode(detectedLanguageCode);

    return formattedLanguageCode in languageCodeMapping ? languageCodeMapping[formattedLanguageCode] : formattedLanguageCode;
};

/**
 * detectValidLanguageForProperty
 * Takes into account available languages for property
 * @param detector
 * @param property
 * @returns
 */
export const detectValidLanguageForProperty = (detector: LanguageDetection, property?: propertyTypes.IProperty): ILanguageResolvingResult =>
    detectValidLanguage(detector, property?.languages || [], property?.settings?.default_language?.code);

/**
 * detectValidLanguage
 */
export const detectValidLanguage = (
    detector: LanguageDetection,
    allowedLanguages: propertyTypes.IProperty["languages"],
    fallbackLanguage = "en-US"
): ILanguageResolvingResult => resolveValidLanguage(detector.detect(), allowedLanguages, fallbackLanguage);

export const resolveValidLanguage = (
    requested: string | TLanguageDetectorResult,
    allowedLanguages: propertyTypes.IProperty["languages"],
    fallbackLanguage = "en-US"
): ILanguageResolvingResult => {
    const isDetectorResult = typeof requested !== "string";
    const requestedLanguage = isDetectorResult ? requested.language : requested;
    const mappedRequestedLanguage = !!requestedLanguage ? mapDetectedLanguage(requestedLanguage) : null;
    const isAvailableLanguage =
        mappedRequestedLanguage && allowedLanguages.find((language) => language.code === mappedRequestedLanguage) !== undefined;
    const didUseFallback = !mappedRequestedLanguage || !isAvailableLanguage;
    const resolvedLanguage = didUseFallback ? fallbackLanguage : mappedRequestedLanguage;

    return {
        resolvedLanguage,
        requested: requestedLanguage,
        mappedTo: mappedRequestedLanguage,
        available: allowedLanguages.map((language) => language.code),
        detectorResult: isDetectorResult ? requested : undefined,
        didUseFallback,
    };
};

interface ILanguageResolvingResult {
    requested: string;
    mappedTo: string | null;
    available: string[];
    detectorResult?: TLanguageDetectorResult;
    resolvedLanguage: string;
    didUseFallback: boolean;
}

type TLanguageDetectorResult = ReturnType<LanguageDetection["detect"]>;
