import { commonTypes } from "@app/common";
import { useQuery } from "react-query";

import { QueryKeys } from "./constants/QueryKeys";
import { getProperty } from "./queries";
import { IProperty } from "./types";

/**
 * useGetProperty
 */
export const useGetProperty = (propertyId: string | undefined, options?: commonTypes.IUseGetOptions) =>
    useQuery<IProperty, Error>([QueryKeys.PropertyItem], () => getProperty(propertyId || ""), options);
