import nativeAxios from "axios";

import { config } from "../config";

export const axios = nativeAxios.create({
    baseURL: config.apiUrl,
    withCredentials: false,
    // Disabled because server side headers
    headers: {
        // "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});
