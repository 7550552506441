import { Slot } from "@radix-ui/react-slot";
import { cn, HorizontalPosition, HorizontalPositions } from "@ui";
import { cva, type VariantProps } from "class-variance-authority";
import React from "react";
import { Transition } from "react-transition-group";

import { LoadingDots } from "../LoadingDots";

const buttonVariants = cva(
    "inline-flex group w-auto flex-shrink-0 items-center justify-center rounded-lg text-base font-normal ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed transition-all",
    {
        variants: {
            variant: {
                default: "bg-primary text-white active:bg-secondary underline-offset-4 disabled:bg-secondary",
                outline:
                    "border text-gray-600 border-gray-250 hover:text-gray-800 hover:bg-gray-50 active:text-gray-800 active:bg-gray-50 active:border-gray-400 disabled:border-gray-250 disabled:bg-white disabled:text-gray-250",
                ghost: "text-gray-600 hover:bg-gray-100 hover:border-gray-100 active:text-gray-900 disabled:text-gray-400 focus-visible:ring-0 focus-visible:ring-none focus-visible:ring-offset-0",
            },
            size: {
                default: "h-11 px-4 py-2",
                sm: "h-9 px-3 text-sm font-light",
                lg: "h-12 px-8",
                icon: "h-8 w-8",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
);

export interface ButtonIconOrSpinnerProps {
    loading: boolean;
    iconPosition: string;
    iconClassName?: string;
    Icon?: React.ElementType;
    transitionState: string;
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    as?: React.ComponentType | keyof JSX.IntrinsicElements;
    icon?: React.ElementType;
    iconClassName?: string;
    iconPosition?: HorizontalPosition;
    isLoading?: boolean;
    loadingText?: string;
}

const Button: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            size,
            asChild = false,
            as = "button",
            icon,
            iconClassName,
            iconPosition = HorizontalPositions.Right,
            children,
            isLoading = false,
            loadingText,
            ...props
        },
        ref
    ) => {
        const Comp: React.ElementType = asChild ? Slot : as;
        const Icon: React.ElementType | undefined = icon;
        const showLoadingText = isLoading && loadingText !== undefined;
        const showButtonIconOrSpinner = Icon !== undefined || isLoading;

        return (
            <Transition in={isLoading} timeout={250}>
                {(state) => (
                    <Comp className={cn(buttonVariants({ size, variant }), className)} ref={ref} {...props}>
                        {showButtonIconOrSpinner && iconPosition === HorizontalPositions.Left ? (
                            <ButtonIconOrSpinner loading={isLoading} iconPosition={iconPosition} Icon={Icon} transitionState={state} />
                        ) : null}

                        {showLoadingText || children ? (
                            <span
                                className={cn(
                                    "text-inherit",
                                    showButtonIconOrSpinner && iconPosition === "right" && "mr-1",
                                    showButtonIconOrSpinner && iconPosition === "left" && "ml-1"
                                )}
                            >
                                {showLoadingText ? loadingText : children}
                            </span>
                        ) : null}

                        {showButtonIconOrSpinner && iconPosition === HorizontalPositions.Right ? (
                            <ButtonIconOrSpinner
                                loading={isLoading}
                                iconPosition={iconPosition}
                                Icon={Icon}
                                transitionState={state}
                                iconClassName={iconClassName}
                            />
                        ) : null}
                    </Comp>
                )}
            </Transition>
        );
    }
);

Button.displayName = "Button";

export const ButtonIconOrSpinner = ({ loading, Icon, transitionState, iconClassName }: ButtonIconOrSpinnerProps) => {
    Icon = Icon!;

    return loading ? <LoadingDots className="fill-primary" /> : <Icon className={cn("h-5", iconClassName)} />;
};

export { Button, buttonVariants };
