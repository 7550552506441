/**
 * defineQueryKey
 * Creates a query key prefixed by namespace
 * @param   {string}    namespace
 * @param   {string}    name of the action
 * @returns {string}    the action name prefixed by the namespace
 */
export const defineQueryKey = (namespace: string, name: string): string => `${namespace}/${name}`;

/**
 * defineQueryKeyCreator
 * Currying the "defineQueryKey" function. This will create a specialized "defineQueryKey" function in which the namespace
 * is preset. The resulting function can be called in a certain scope without the need to provide the namespace.
 * i.e. defineQueryKeyCreator('navigation')('navigation-items') will return navigation/navigation-items
 * @param   {string}    namespace
 * @return  {function}  customized define function
 */
export const defineQueryKeyCreator = (namespace: string) => (name: string) => defineQueryKey(namespace, name);
