import { MinusIcon } from "@heroicons/react/24/solid";
import { Button, Flex, Text } from "@ui";
import React from "react";
import { useController } from "react-hook-form";

import { PlusIcon } from "../../../componentLibrary/icon/PlusIcon";

interface CountInputProps {
    name: string;
    control: any; // Update with proper type for react-hook-form control
    defaultValue: number;
    minValue: number;
    maxValue?: number;
}

export const CountInputField: React.FC<CountInputProps> = React.forwardRef(
    ({ name, defaultValue, minValue, maxValue, control }, ref: any) => {
        const {
            field: { value, onChange },
        } = useController({
            name,
            control,
            defaultValue,
        });

        const increaseCount = () => {
            if (!maxValue || (maxValue && value < maxValue)) {
                onChange(Number(value) + 1);
            }
        };

        const decreaseCount = () => {
            if (value > 0) {
                onChange(Number(value) - 1);
            }
        };

        return (
            <div className="flex gap-x-4 items-center" ref={ref}>
                <Flex alignItems="center" justifyContent="center">
                    <Button
                        data-testid="decrease-guest-count"
                        variant="outline"
                        size="icon"
                        onClick={decreaseCount}
                        disabled={value === minValue}
                    >
                        <MinusIcon width={14} />
                    </Button>
                </Flex>

                <Text className="text-base font-medium text-gray-600 w-8">{value}</Text>

                <Button
                    data-testid="increase-guest-count"
                    variant="outline"
                    size="icon"
                    onClick={increaseCount}
                    disabled={value === maxValue}
                >
                    <PlusIcon />
                </Button>
            </div>
        );
    }
);
