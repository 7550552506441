import { useEffect } from "react";

export interface IBodyScrollPreventerProps {
    open: boolean;
}

export const BodyScrollPreventer = ({ open }: IBodyScrollPreventerProps) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [open]);

    return null;
};
