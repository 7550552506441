import { appHooks } from "@app/app";
import { loadStripe, type Stripe } from "@stripe/stripe-js";
import React, { useEffect, useMemo, useState } from "react";

import { IStripeContext, StripeContext } from "../context/StripeContext";

export interface IStripeProvider {
    children?: React.ReactNode | React.ReactNode[];
    stripePublishableKey: string;
}

/**
 * StripeProvider
 * @info - https://docs.stripe.com/stripe-js/react
 * @note - children components are not rendered before stripe is loaded
 * and stripe checkout session is created
 */
export const StripeProvider = ({ stripePublishableKey, children }: IStripeProvider) => {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(Promise.resolve(null));
    const [stripe, setStripe] = useState<Stripe>();
    const { getState } = appHooks.useBookingEngineStateContext();
    const { property } = getState();

    useEffect(() => {
        if (property) {
            const paymentProvider = property?.payment_provider;
            const isStripePaymentProvider = paymentProvider?.client === "stripe";
            const stripeAccountId = property?.external_payment_provider_account_id || undefined;

            // Note: stripeAccountId may be undefined. Any transactions will go
            // to the top-level Stripe account.
            // stripeAccountId is used as an optional subaccount id.

            if (!paymentProvider) {
                throw new Error("No payment provider configured");
            } else if (!isStripePaymentProvider) {
                throw new Error("Configured payment provider is not Stripe");
            }

            const nextStripePromise = loadStripe(stripePublishableKey, {
                stripeAccount: stripeAccountId,
            });

            nextStripePromise.then((nextStripe) => {
                if (nextStripe) {
                    setStripe(nextStripe);
                }
            });

            setStripePromise(nextStripePromise);
        }
    }, [property]);

    const value: IStripeContext = useMemo(() => ({ stripe, stripePromise, isInitialized: !!stripePromise }), [stripe, stripePromise]);

    return <StripeContext.Provider value={value}>{children}</StripeContext.Provider>;
};
