export const Trash = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <mask id="mask0_1095_1640" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
            <rect width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1095_1640)">
            <path
                d="M5.8335 17.5C5.37516 17.5 4.9828 17.3368 4.65641 17.0104C4.33002 16.684 4.16683 16.2917 4.16683 15.8333V5C3.93072 5 3.7328 4.92014 3.57308 4.76042C3.41336 4.60069 3.3335 4.40278 3.3335 4.16667C3.3335 3.93056 3.41336 3.73264 3.57308 3.57292C3.7328 3.41319 3.93072 3.33333 4.16683 3.33333H7.50016C7.50016 3.09722 7.58002 2.89931 7.73975 2.73958C7.89947 2.57986 8.09738 2.5 8.3335 2.5H11.6668C11.9029 2.5 12.1009 2.57986 12.2606 2.73958C12.4203 2.89931 12.5002 3.09722 12.5002 3.33333H15.8335C16.0696 3.33333 16.2675 3.41319 16.4272 3.57292C16.587 3.73264 16.6668 3.93056 16.6668 4.16667C16.6668 4.40278 16.587 4.60069 16.4272 4.76042C16.2675 4.92014 16.0696 5 15.8335 5V15.8333C15.8335 16.2917 15.6703 16.684 15.3439 17.0104C15.0175 17.3368 14.6252 17.5 14.1668 17.5H5.8335ZM7.50016 14.1667H9.16683V6.66667H7.50016V14.1667ZM10.8335 14.1667H12.5002V6.66667H10.8335V14.1667Z"
                fill="currentColor"
            />
        </g>
    </svg>
);
