import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Button } from "@ui";
import { useTranslation } from "react-i18next";

import { appHooks } from "..";
import { useCustomNavigate } from "../hooks";
import { useRouter } from "./router/RouterContext";

export const NavigateBack = () => {
    const { getState } = appHooks.useBookingEngineStateContext();
    const { activeBookingIndex, setIndex, setPrevious } = appHooks.useActiveBookingContext();
    const { bookingState } = getState();
    const navigate = useCustomNavigate();
    const { data, route } = useRouter();
    const bookingsCount = (bookingState?.bookings || []).length;
    const isMultiRoomBooking = bookingsCount > 1;
    const activeStep = route.query.c;
    const { t } = useTranslation(["navigation"]);

    // @TODO - fix issue where data doesnt contain data of the related step but from the previous step.
    // Occurs when the CheckoutReturnPage is navigating to the CheckoutThankYouPage
    // tmp workaound to hide back button on thank you page
    const isLastStep = activeStep === "thank-you";

    const handleGoBack = () => {
        let to = data.back;

        if (isMultiRoomBooking) {
            if (activeStep === "summary") {
                to = "?p=base&c=item";
                setIndex(bookingsCount - 1);
            } else if (activeStep === "item") {
                to = "?p=base&c=availability";
            } else if (activeStep === "availability") {
                if (activeBookingIndex > 0) {
                    to = "?p=base&c=item";
                    setPrevious();
                } else {
                    to = "?p=base&c=search";
                }
            }
        }

        navigate(to);
    };

    return data?.back && !isLastStep ? (
        <Button
            data-testid="navigate-back-btn"
            className="p-0 active:bg-white hover:bg-white hover:underline underline-offset-4 h-auto"
            icon={ChevronLeftIcon}
            iconPosition="left"
            onClick={handleGoBack}
            variant="ghost"
        >
            <span className="ml-2">{t("back")}</span>
        </Button>
    ) : null;
};
