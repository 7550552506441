export enum PaymentSetupIntentStatus {
    RequiresPaymentMethod = "requires_payment_method",

    RequiresConfirmation = "requires_confirmation",

    RequiresAction = "requires_action",

    Processing = "processing",

    Canceled = "canceled",

    Succeeded = "succeeded",
}
