import { SearchItemPage } from "@app/availability/pages/SearchItemPage";
import { reactLazyPreload } from "@app/common";
import React from "react";

import { AppWidgetsManager } from "./AppWidgetsManager";
import { Route } from "./router/Route";

// export const SearchItemPage = reactLazyPreload(() =>
//     import("@app/availability/pages/SearchItemPage").then((module) => ({
//         default: module.SearchItemPage,
//     }))
// );

export const AvailabilityOverviewPage = reactLazyPreload(() =>
    import("@app/availability/pages/AvailabilityOverviewPage").then((module) => ({
        default: module.AvailabilityOverviewPage,
    }))
);

export const BookableItemPage = reactLazyPreload(() =>
    import("@app/booking/pages/BookableItemPage").then((module) => ({ default: module.BookableItemPage }))
);

export const SummaryItemPage = reactLazyPreload(() =>
    import("@app/booking/pages/SummaryItemPage").then((module) => ({
        default: module.SummaryItemPage,
    }))
);

export const RoomItemPage = reactLazyPreload(() =>
    import("@app/room/pages/RoomItemPage").then((module) => ({
        default: module.RoomItemPage,
    }))
);

export const PackageItemPage = reactLazyPreload(() =>
    import("@app/package/pages/PackageItemPage").then((module) => ({ default: module.PackageItemPage }))
);

export const CheckoutItemPage = reactLazyPreload(() =>
    import("@app/checkout/pages/CheckoutItemPage").then((module) => ({
        default: module.CheckoutItemPage,
    }))
);

export const CheckoutThankYouItemPage = reactLazyPreload(() =>
    import("@app/checkout/pages/CheckoutThankYouItemPage").then((module) => ({
        default: module.CheckoutThankYouItemPage,
    }))
);

export const CheckoutReturnPage = reactLazyPreload(() =>
    import("@app/checkout/pages/CheckoutReturnPage").then((module) => ({
        default: module.CheckoutReturnPage,
    }))
);

export const Routes = (
    <Route path={"?p=base"} element={<AppWidgetsManager />}>
        <Route path="?p=base&c=search" element={<SearchItemPage />} data={{ label: "booking", back: "", next: "?p=base&c=availability" }} />
        <Route
            path="?p=base&c=availability"
            element={<AvailabilityOverviewPage />}
            data={{ label: "selectDates", back: "?p=base&c=search", next: "?p=base&c=item" }}
        />
        <Route
            path="?p=base&c=item"
            element={<BookableItemPage />}
            data={{ label: "select", back: "?p=base&c=availability", next: "?p=base&c=summary" }}
        />
        <Route
            path="?p=base&c=summary"
            element={<SummaryItemPage />}
            data={{ label: "roomDetail", back: "?p=base&c=item", next: "?p=base&c=checkout" }}
        />
        <Route
            path="?p=base&c=checkout-return"
            element={<CheckoutReturnPage />}
            data={{ label: "checkoutReturn", back: "?p=base&c=checkout", next: "?p=base&c=thank-you" }}
        />

        <Route
            path="?p=base&c=checkout"
            element={<CheckoutItemPage />}
            data={{ label: "bookingSummary", back: "?p=base&c=summary", next: "?p=base&c=thank-you" }}
        />

        <Route path="?p=base&c=thank-you" element={<CheckoutThankYouItemPage />} />
        <Route path="*" element={<React.Fragment />} />
    </Route>
);
