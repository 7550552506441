/* eslint-disable @typescript-eslint/naming-convention */

import { appTypes } from "@app/app";
import { commonTypes } from "@app/common";
import { propertyTypes } from "@app/property";

import { TrackingActions } from "../constants/TrackingActions";
import { TrackingCategories } from "../constants/TrackingCategories";
import { IEcommerceItem, TItemType, TTrackingActionMap, TTrackingCategoryMap } from "../types";

/**
 * getMappedTrackingAction
 *
 * @param action
 * @param mapping
 */
export const getMappedTrackingAction = (action: TrackingActions, mapping: TTrackingActionMap) =>
    action in mapping ? mapping[action] : action;

/**
 * getMappedTrackingCategory
 *
 * @param action
 * @param mapping
 */
export const getMappedTrackingCategory = (category: TrackingCategories, mapping: TTrackingCategoryMap) =>
    category in mapping ? mapping[category] : category;

export const adaptItemsByType = (
    items: commonTypes.TBookableItem[] | commonTypes.TPriceableItem[] | appTypes.IBookingState,
    itemType: TItemType,
    property: propertyTypes.IProperty
) => {
    switch (itemType) {
        case "bookable":
            return adaptBookableItems(items as commonTypes.TBookableItem[], property);
        case "priceable":
            return adaptPriceableItems(items as commonTypes.TPriceableItem[], property);
        case "booking":
            return adaptFullBookingDetailItems(items as appTypes.IBookingState, property);
    }
};

/**
 * adaptPriceableItems
 * Adapt priceable items => ecommerce items
 * @param bookableItems
 * @returns
 */
export const adaptPriceableItems = (
    priceableItems: commonTypes.TPriceableItem[],
    property: propertyTypes.IProperty
): Array<Partial<IEcommerceItem>> =>
    priceableItems.map((priceableItem, index) => ({
        item_id: priceableItem.id,
        item_name: priceableItem.name[property.settings.default_language.code],
        item_variant: priceableItem.priceable_type,
        item_brand: `${property.name} (${property.id})`,
        item_list_name: "Packages",
        index,
        quantity: 1,
    }));

/**
 * adaptBookableItems
 * Adapt bookable items => ecommerce items
 * @param bookableItems
 * @returns
 */
export const adaptBookableItems = (
    bookableItems: commonTypes.TBookableItem[],
    property: propertyTypes.IProperty
): Array<Partial<IEcommerceItem>> =>
    bookableItems.map((bookableItem, index) => ({
        item_id: bookableItem.id,
        item_name: bookableItem.name[property.settings.default_language.code],
        item_variant: bookableItem.priceable_type,
        item_brand: `${property.name} (${property.id})`,
        item_list_name: bookableItem.bookable_type === commonTypes.BookableType.Package ? "Packages" : "Rooms",
        index,
        quantity: 1,
    }));

/**
 * adaptFullBookingDetailItems
 * Adapt bookable items => ecommerce items
 * @param bookableItems
 * @returns
 */
export const adaptFullBookingDetailItems = (
    fullBookingDetailItems: appTypes.IBookingState,
    property: propertyTypes.IProperty
): Array<Partial<IEcommerceItem>> =>
    (fullBookingDetailItems?.bookings || []).reduce((aggr, fullBookingDetailItem) => {
        if (fullBookingDetailItem.room) {
            aggr.push(adaptBookableItems([fullBookingDetailItem.room], property)[0]);
        }

        if (fullBookingDetailItem.package) {
            aggr.push(adaptBookableItems([fullBookingDetailItem.package], property)[0]);
        }

        if (fullBookingDetailItem.extras) {
            adaptPriceableItems(fullBookingDetailItem.extras, property).forEach((adaptedExtraItem) => aggr.push(adaptedExtraItem));
        }

        return aggr;
    }, [] as Array<Partial<IEcommerceItem>>);
