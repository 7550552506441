import { availabilityTypes } from "@app/availability";

import { commonTypes } from ".";
// eslint-disable-next-line import/order
import { CrudStates } from "./constants/CrudStates";

export type TBookableItem = availabilityTypes.IPackageAvailability | availabilityTypes.IRoomAvailability;

export type TPriceableItem = TBookableItem | IExtra;

export type TDiscount = IPromoDiscount | ICorpDiscount;

export enum BookableType {
    Package = "package",
    Room = "room",
}

export interface IBookableItemsFilter {
    type: BookableType | "rp" | undefined;
    filters: string[];
}

export enum PriceableType {
    Package = "package",
    Room = "room",
    Rate = "rate",
    Extra = "extra",
}

export enum GuestType {
    Adult = "adult",
    Child = "child",
    Infant = "infant",
}

export enum DiscountType {
    Corp = "corp",
    Promo = "promo",
}

export interface IPrice {
    original: number;
    discounted: number | undefined;
}

export interface IPromoDiscount {
    valid_from: string;
    valid_till: string;
    type: "access" | "discount";
    discount_type: string;
    discount_rate: string;
    discount_rate_type: 0 | 1;
    disable_rate_discount: boolean;
    single_use: boolean;
}

export interface IPromoCode {
    id: string;
    code: string;
    type: "access" | "discount";
    amount: string;
    unit: "percent" | "fixed";
}

export interface ICorpDiscount {
    discount_type: DiscountType.Corp;
}

export interface IAddress {
    line1?: string;
    line2: string;
    city: string;
    country?: ICountry; // change to country code enum?
    state?: string;
    email: string;
    phone: string;
    zip: string;
}

export interface ICountry {
    id: string;
    name: string;
    code: string;
}

export enum ExtraPriceCalculation {
    PerStay = "per_stay",
    PerNight = "per_night",
    PerPerson = "per_person",
    PerPersonPerNight = "per_person_per_night",
}

export interface IExtra {
    id: string;
    name: commonTypes.ITranslation;
    description: string;
    type: string;
    rate: number;
    min_rate: number;
    child_rate: number | null;
    images: commonTypes.IImage[];
    priceable_type: PriceableType.Extra;
}

export interface IFee {
    id: string;
    name: string;
    description: string;
    type: string;
    unit: string;
    amount: number;
}

export interface IExchangeRates {
    success: boolean;
    base: string;
    date: string;
    rates: { [key: string]: number };
}

export interface IRate {
    id: string;
    name: ITranslation;
    description: ITranslation;
    rate_type: RateType;
    fees: IFee[];
    default_minlos: number;
    default_maxlost: number;
    pros_cons: IProsCons[];
    usps: Array<{ name: string; type: string }>;
    child_rates: IChildRate[];
    priceable_type: PriceableType.Rate;
    cancellation_policy: ICancellationPolicy;
}

export interface ICancellationPolicy {
    id: string;
    name: commonTypes.ITranslation;
    description?: commonTypes.ITranslation;
    refundable: boolean;
    window_days?: number;
    window_hours?: number;
    window_from?: "midnight-before-arrival";
}

export enum RateType {
    PerPersonPerNight = "pppn",
    PerRoomPerNight = "prpn",
    PerPerson = "pp",
    PerStay = "ps",
    PerPersonPerDay = "pppd",
    PerRoomPerDay = "prpd",
}

export interface IChildRate {
    id: string;
    name: string;
    rate: number;
    rate_unit: string;
}

// export interface IGuest {
//     currency: string;
//     guest_amount: number;
//     guest_type: string; // enum
//     price: number;
// }

export interface IImage {
    id: number;
    name: string;
    path: string;
}

export interface IProsCons {}

/**
 * IUseGetOptions
 * Type to describe a generic options object which can be used when composing "get" hooks which consume
 * the ReactQuery "useQuery" hook.
 */
export interface IUseGetOptions {
    enabled?: boolean; // The query will not execute until enabled is true
    retry?: boolean;
    initialData?: any;
    onError?: any;
}

/**
 * TCrudStateFlags
 */
export type TCrudStateFlags = { [key in Partial<CrudStates>]: boolean };

/**
 * TItemId
 */
export type TItemId = string | number;

export interface ITranslation {
    [key: string]: string;
}

export interface IFilter {
    id: string;
    name: ITranslation;
}

export interface IFilterGroup {
    id: string;
    name: ITranslation;
    filters: IFilter[];
}

export interface ICurrency {
    id: string;
    code: string;
    label: string;
    symbol: string;
}

export interface ILanguage {
    id: string;
    code: string;
    direction: "lr" | "rl";
    label: string;
}

export interface ITerm {
    id: string;
    name: ITranslation;
    description: ITranslation;
    link: ITranslation;
}
