import { commonTypes } from "@app/common";
import { useMutation, useQuery } from "react-query";

import { QueryKeys } from "./constants/QueryKeys";
import { cancelBooking, createBooking, getCart, getReservedBooking, updateBooking } from "./queries";
import { IBookingRequest, ICart, IReservedBooking } from "./types";

/**
 * useGetCart
 */
export const useGetCart = (propertyId: string | undefined, searchQuery: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<ICart, Error>([QueryKeys.BookingCart, searchQuery], () => getCart(propertyId || "", searchQuery), options);

/**
 * useCreateBooking
 */
export const useCreateBooking = () => useMutation(createBooking);

/**
 * useUpdateBooking
 */
export const useUpdateBooking = (bookingId: string) => useMutation((data: IBookingRequest) => updateBooking(bookingId, data));

/**
 * useCancelBooking
 */
export const useCancelBooking = () => useMutation(cancelBooking);

/**
 * useGetBooking
 */
export const useGetReservedBooking = (propertyId: string | undefined, id: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<IReservedBooking, Error>([QueryKeys.BookingItem, id], () => getReservedBooking(propertyId || "", id), options);
