import { queryClient } from "@app/app";
import { availabilityTypes } from "@app/availability";
import { commonTypes } from "@app/common";
import { useQuery } from "react-query";

import { QueryKeys } from "./constants/QueryKeys";
import { checkAvailability, getAvailability, getCalendarAvailability } from "./queries";
import { IAvailabilityCheck } from "./types";

/**
 * useGetAvailability
 */
export const useGetAvailability = (propertyId: string | undefined, searchQuery: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<availabilityTypes.IAvailability[], Error>(
        [QueryKeys.AvailabilityItems, propertyId, searchQuery],
        () => getAvailability(propertyId || "", searchQuery),
        options
    );

/**
 * useCheckAvailability
 */
export const useCheckAvailability = (propertyId: string | undefined, searchQuery: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<IAvailabilityCheck, Error>(
        [QueryKeys.AvailabilityCheck, searchQuery],
        () => checkAvailability(propertyId || "", searchQuery),
        options
    );

/**
 * useGetCalendarAvailability
 */
export const useGetCalendarAvailability = (propertyId: string, fromDate: string, toDate: string, options?: commonTypes.IUseGetOptions) =>
    useQuery<availabilityTypes.ICalendarAvailability, Error>(
        [QueryKeys.CalendarAvailabilityItems, propertyId, { fromDate, toDate }],
        () => getCalendarAvailability(propertyId, fromDate, toDate),
        {
            ...options,
            initialData: () => {
                const calendar = queryClient.getQueriesData<availabilityTypes.ICalendarAvailability>([
                    QueryKeys.CalendarAvailabilityItems,
                    propertyId,
                ]);

                return calendar.map((d) => d[1]).reduce((el, acc) => ({ ...acc, ...el }), {});
            },
            onSuccess: (data) => {
                const calendar = queryClient.getQueriesData<availabilityTypes.ICalendarAvailability>([
                    QueryKeys.CalendarAvailabilityItems,
                    propertyId,
                ]);

                queryClient.setQueryData([QueryKeys.CalendarAvailabilityItems, propertyId, { fromDate, toDate }], () => ({
                    ...calendar.map((d) => d[1]).reduce((el, acc) => ({ ...acc, ...el }), {}),
                    ...data,
                }));
            },
        }
    );
