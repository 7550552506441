import { cn } from "@ui";
import { cva, type VariantProps } from "class-variance-authority";
import React from "react";

const titleVariants = cva("text-gray-900", {
    variants: {
        size: {
            xxl: "text-2xl font-bold",
            xl: "text-xl",
            lg: "text-lg",
            base: "text-base",
            sm: "text-sm",
        },
    },
    defaultVariants: {
        size: "xxl",
    },
});

export interface ITitleProps extends React.HTMLAttributes<HTMLParagraphElement>, VariantProps<typeof titleVariants> {}

const Title: React.FC<ITitleProps> = React.forwardRef<HTMLParagraphElement, ITitleProps>(
    ({ className, size, children, ...restProps }, ref) => {
        const {} = restProps;

        return (
            <h1 ref={ref} className={cn(titleVariants({ size }), "leading-none", className)} {...restProps}>
                {children}
            </h1>
        );
    }
);

Title.displayName = "Title";

export default Title;
