import { axios } from "@app/app";
import { IExtra } from "@app/common/types";

export const getRoomRateExtras = async (
    propertyId: string | undefined,
    roomId: string | undefined,
    rateId: string | undefined,
    languageCode: string
): Promise<IExtra[]> => {
    const response = await axios.get<any>(`/properties/${propertyId}/rooms/${roomId}/extras/${rateId}?lg=${languageCode}`);

    return response.data;
};
