export const HotelchampLogo = () => (
    <svg width="85" height="16" viewBox="0 0 85 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="605cbe5544627f1f913023de_logo 1" clipPath="url(#clip0_2961_440)">
            <path
                id="Vector"
                d="M26.0866 8.55175V12.1802H24.436V8.36678C24.436 7.66957 23.9522 7.24267 23.3973 7.24267C22.7001 7.24267 22.259 7.7407 22.259 8.4237V12.1944H20.6084V3.51465H22.259V6.64506C22.5293 6.2182 23.0985 5.76284 23.9095 5.76284C25.7166 5.74863 26.0866 7.22847 26.0866 8.55175Z"
                fill="white"
            />
            <path
                id="Vector_2"
                d="M30.3694 5.74829C32.2903 5.74829 33.7559 7.17121 33.7559 9.06369C33.7559 10.9562 32.333 12.3364 30.3694 12.3364C28.42 12.3364 26.9971 10.9562 26.9971 9.06369C26.9971 7.157 28.4627 5.74829 30.3694 5.74829ZM30.3694 10.8708C31.3654 10.8708 32.0911 10.1309 32.0911 9.06369C32.0911 7.9965 31.3654 7.21392 30.3694 7.21392C29.3733 7.21392 28.6476 7.9823 28.6476 9.06369C28.6619 10.1309 29.3875 10.8708 30.3694 10.8708Z"
                fill="white"
            />
            <path
                id="Vector_3"
                d="M34.8661 4.58179H36.5024V5.90511H37.6834V7.24265H36.5024V9.66158C36.5024 10.6007 36.787 10.7857 37.4273 10.7857C37.5411 10.7857 37.598 10.7857 37.7261 10.7715V12.1944C37.7261 12.1944 37.47 12.2513 37.0146 12.2513C35.5206 12.2513 34.8518 11.4118 34.8518 9.70429V7.24265H34.1973V5.90511H34.8661V4.58179Z"
                fill="white"
            />
            <path
                id="Vector_4"
                d="M41.4397 5.74829C43.3891 5.74829 44.4847 7.04316 44.4847 9.09215C44.4847 9.20599 44.4847 9.33404 44.4705 9.44788H40.0595C40.1021 10.2874 40.7709 10.885 41.6958 10.885C42.5496 10.885 43.0333 10.387 43.2183 10.1167L44.314 10.9419C44.0721 11.383 43.2326 12.3222 41.6674 12.3222C39.6468 12.3222 38.3662 10.9277 38.3662 9.04948C38.3662 7.157 39.6468 5.74829 41.4397 5.74829ZM42.8057 8.38069C42.763 7.61231 42.2223 7.11429 41.4681 7.11429C40.6855 7.11429 40.1875 7.66923 40.1164 8.38069H42.8057Z"
                fill="white"
            />
            <path id="Vector_5" d="M45.4097 3.50024H47.06V12.18H45.4097V3.50024Z" fill="white" />
            <path
                id="Vector_6"
                d="M52.8798 10.3022L53.3207 11.6539C52.9651 11.9385 52.2822 12.3227 51.3716 12.3227C49.422 12.3227 48.0278 10.9567 48.0278 9.05002C48.0278 7.14328 49.4504 5.73462 51.3573 5.73462C52.2822 5.73462 52.9224 6.09035 53.3353 6.40336L52.8656 7.79782C52.5952 7.54172 52.1113 7.2002 51.4 7.2002C50.3326 7.2002 49.6782 8.0255 49.6782 9.03576C49.6782 10.103 50.4183 10.8571 51.471 10.8571C52.097 10.8713 52.6094 10.5298 52.8798 10.3022Z"
                fill="white"
            />
            <path
                id="Vector_7"
                d="M59.7383 8.55202V12.1804H58.0879V8.36701C58.0879 7.6698 57.604 7.24295 57.049 7.24295C56.3519 7.24295 55.9106 7.74093 55.9106 8.42393V12.1947H54.2603V3.51489H55.9106V6.64529C56.181 6.21843 56.7502 5.76311 57.5614 5.76311C59.3684 5.74886 59.7383 7.2287 59.7383 8.55202Z"
                fill="white"
            />
            <path
                id="Vector_8"
                d="M63.0679 8.15355C63.7081 8.15355 64.1921 8.33856 64.4198 8.49507V8.08242C64.4198 7.52747 64.0069 7.15753 63.2814 7.15753C62.6554 7.15753 62.0862 7.35671 61.6164 7.59864L60.9904 6.47454C61.5596 6.09035 62.4987 5.73462 63.4666 5.73462C65.4867 5.73462 66.0844 6.78755 66.0844 8.19626V12.1662H64.6192L64.5051 11.7535C64.1778 12.095 63.7081 12.3227 62.9826 12.3227C61.7163 12.3227 60.6348 11.5401 60.6348 10.2168C60.6348 8.9931 61.6022 8.15355 63.0679 8.15355ZM63.3809 11.0563C64.0069 11.0563 64.4624 10.7148 64.4624 10.1883C64.4624 9.63338 64.0069 9.30611 63.3667 9.30611C62.7264 9.30611 62.2855 9.66184 62.2855 10.1883C62.2855 10.7006 62.7548 11.0563 63.3809 11.0563Z"
                fill="white"
            />
            <path
                id="Vector_9"
                d="M76.2728 12.1799H74.622V8.42336C74.622 7.68344 74.2522 7.24238 73.6404 7.24238C73.0427 7.24238 72.6161 7.68344 72.6161 8.43761V12.1799H70.9653V8.36644C70.9653 7.65503 70.6096 7.24238 69.9978 7.24238C69.4144 7.24238 68.9731 7.68344 68.9731 8.42336V12.1799H67.3228V5.9048H68.9447V6.63051C69.1724 6.20365 69.7274 5.74829 70.5812 5.74829C71.392 5.74829 71.9754 6.10402 72.3026 6.68743C72.6729 6.11827 73.3273 5.74829 74.1669 5.74829C75.5899 5.74829 76.3012 6.70164 76.3012 8.03917V12.1799H76.2728Z"
                fill="white"
            />
            <path
                id="Vector_10"
                d="M77.5107 5.9048H79.0758V6.55938C79.2468 6.34591 79.8728 5.74829 80.9402 5.74829C82.733 5.74829 84.0135 7.11429 84.0135 9.03523C84.0135 10.9846 82.8325 12.3364 81.0396 12.3364C79.958 12.3364 79.332 11.7388 79.1757 11.5396V14.4281H77.5249V5.9048H77.5107ZM80.7266 10.8708C81.6799 10.8708 82.3343 10.1167 82.3343 9.02102C82.3343 7.91112 81.6656 7.21392 80.7266 7.21392C79.7161 7.21392 79.1184 8.02496 79.1184 9.02102C79.1184 10.202 79.816 10.8708 80.7266 10.8708Z"
                fill="white"
            />
            <path
                id="Vector_11"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.986328 0V15.6663H16.6526V0H0.986328ZM10.4345 3.47191H12.7396V7.42759C12.6969 7.37071 12.6542 7.29954 12.6115 7.24262C12.085 6.53116 11.3594 6.09005 10.4345 5.9478V3.47191ZM12.7396 12.1944H10.4345V10.1596C10.4345 9.2774 9.70878 8.55169 8.82656 8.55169C7.94439 8.55169 7.21868 9.2774 7.21868 10.1596V12.1944H4.89934V3.47191H7.20447V7.45605C7.73092 6.82997 8.6416 6.46003 9.69457 6.46003C9.95067 6.46003 10.1926 6.4885 10.4202 6.51696C11.1459 6.645 11.7151 7.00073 12.1278 7.56989C12.5404 8.1533 12.6969 8.8363 12.7254 10.1738C12.7254 10.2877 12.7254 10.4015 12.7254 10.5154V12.1944H12.7396Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_2961_440">
                <rect width="83.027" height="15.9838" fill="white" transform="translate(0.986328)" />
            </clipPath>
        </defs>
    </svg>
);
